import { NumerosALetras as convertNumberToLetters } from "numero-a-letras";

export const getNumberFormatted = (number) => {
  number = Number(number).toFixed(2);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const fullTrim = (str_value) => {
  return `${str_value}`
    .replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, "")
    .replace(/\s+/g, " ");
};

export const numberToLetters = (number) => {
  const splitNumber = `${number}`.split(".");
  let response = getNumberToLetterConverted(+splitNumber[0]);

  if (splitNumber.length > 1) {
    // 100.5 = 50 centavos
    if (splitNumber[1].length == 1) {
      splitNumber[1] += "0";
    }
    const strCentavos = getNumberToLetterConverted(+splitNumber[1])
      .replace("lempiras", "centavos")
      .replace("lempira", "centavo");
    response += ` con ${strCentavos}`;
  }

  if (Number.isInteger(number)) {
    const splitText = response.split("lempiras");
    response = splitText[0] + " lempiras";
  }

  return response;
};

function getNumberToLetterConverted(number) {
  return convertNumberToLetters(number)
    .toLowerCase()
    .replace("m.n.", "")
    .replace("peso", "lempira")
    .replace("/", "")
    .replace(/[0-9]/g, "");
}
