import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import uuid from "uuid";

import NewNavbar from "../../layout/NewNavbar";

import {
  configMaterialComponents,
  removeMaterialComponents,
  showModal,
  showToast,
  notificationError,
} from "../../../utils/MaterialFunctions";

import TextInputField from "../../common/TextInputField";
import CheckInputField from "../../common/CheckInputField";
import ButtonField from "../../common/ButtonField";
import Spinner from "../../common/Spinner";
import EmptyIcon from "../../common/EmptyIcon";
import ProductCardInventory from "../../common/ProductCardInventory";

import {
  searchProductsByUbication,
  cleanProductsReducer,
  updateProductsLocalInventory,
} from "../../../actions/productActions";

import SearchProductAndShowInfo from "../../layout/modals/SearchProductAndShowInfo";

class ProductsInventory extends Component {
  state = {
    search_ubication: "",
    products: [],
    products_setted: false,
    mobile_version: false,
  };
  changed_products = false; // Valida si la informacion cambio y no perder los nuevos valores
  is_searching_product = false;
  current_id_product = null;

  componentWillUnmount() {
    removeMaterialComponents();
    this.props.cleanProductsReducer();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.cleanProductsReducer();
  }

  componentDidUpdate() {
    if (this.current_id_product) {
      const input = document.getElementById(this.current_id_product);
      if (!input) return;
      input.focus();
      this.current_id_product = null;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.product &&
      !nextProps.product.loading &&
      this.state.products_setted === false
    ) {
      const newProducts = JSON.parse(
        JSON.stringify(nextProps.product.products)
      );
      newProducts.forEach((p) => {
        p.inventory_correct = false;
        p.base_existencia = p.existencia;
      });
      this.setState({
        products: newProducts,
        products_setted: true,
      });
      this.props.cleanProductsReducer();
      this.changed_products = false;
    }
  }

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeCheckField = (e) => {
    const current_value = this.state[e.target.name];
    this.setState({ [e.target.name]: !current_value });
  };

  onChangeCheckInput = (incomingProduct) => {
    const { products } = this.state;
    const productIndex = this.getProductIndex(incomingProduct.id);
    if (productIndex < 0) return;

    const product = products[productIndex];
    product.inventory_correct = !product.inventory_correct;
    this.changed_products = true;
    this.setState({
      products,
    });
  };

  getProductIndex = (id) => {
    return this.state.products.findIndex((p) => p.id === id);
  };

  onChangeProductInventory = (product, event) => {
    const { existencia } = product;
    const newValue = +event.target.value;
    if (existencia === newValue || isNaN(newValue)) return;

    const productIndex = this.getProductIndex(product.id);
    if (productIndex < 0) return;

    const { products } = this.state;
    const existingProduct = products[productIndex];
    existingProduct.existencia = newValue;
    existingProduct.changed = true;
    products[productIndex] = existingProduct;
    this.current_id_product = product.id;
    this.setState({
      products,
    });
    this.changed_products = true;
  };

  onAddInventoryUnity = (product) => {
    const productIndex = this.getProductIndex(product.id);
    if (productIndex < 0) return;

    const { products } = this.state;
    const existingProduct = products[productIndex];
    existingProduct.existencia = parseInt(existingProduct.existencia) + 1;
    existingProduct.changed = true;
    products[productIndex] = existingProduct;
    this.setState({
      products,
    });
    this.changed_products = true;
  };

  onRemoveInventoryUnity = (product) => {
    const productIndex = this.getProductIndex(product.id);
    if (productIndex < 0) return;

    const { products } = this.state;
    const existingProduct = products[productIndex];
    const newExistencia = parseInt(existingProduct.existencia) - 1;
    if (newExistencia < 0) return;
    existingProduct.existencia = newExistencia;
    existingProduct.changed = true;
    products[productIndex] = existingProduct;
    this.setState({
      products,
    });
    this.changed_products = true;
  };

  onSearchUbication = () => {
    if (this.state.search_ubication.trim().length == 0) return;
    if (this.changed_products) {
      const confirmContinue = window.confirm(
        "Desea realizar la nueva busqueda? Se perderan todos los cambios"
      );
      if (!confirmContinue) return;
    }
    this.setState({
      products_setted: false,
    });
    this.props.searchProductsByUbication(this.state.search_ubication);
  };

  onRemoveProductClick = (product) => {
    const productIndex = this.getProductIndex(product.id);
    if (productIndex < 0) return;

    const { products } = this.state;
    products.splice(productIndex, 1);
    this.setState({
      products,
    });
  };

  onAddProductClick = () => {
    this.is_searching_product = true;
    showModal("search_product_and_show_info");
  };

  onCancelSelectProduct = () => {
    this.is_searching_product = false;
  };

  onAddProductToArray = (product) => {
    this.is_searching_product = false;
    const newProduct = {
      ...product,
      id: product.id_producto_local,
      id_producto: product.id,
      base_existencia: product.existencia,
      inventory_correct: false,
    };

    const productIndex = this.getProductIndex(newProduct.id);
    if (productIndex >= 0) return;

    this.changed_products = true;
    const { products } = this.state;
    products.splice(0, 0, JSON.parse(JSON.stringify(newProduct)));
    this.setState({
      products,
    });
  };

  onSaveSingleProductClick = (incomingProduct) => {
    const productIndex = this.getProductIndex(incomingProduct.id);
    if (productIndex < 0) return;

    const product = this.state.products[productIndex];
    if (
      product.base_existencia == product.existencia &&
      !product.inventory_correct
    ) {
      return notificationError("No hay actualizaciones para guardar");
    }
    this.sendProductsForUpdate([product]);
  };

  onSaveClick = () => {
    if (!this.changed_products) {
      return notificationError("No hay actualizaciones para guardar");
    }
    const products = this.state.products.filter(
      (p) => p.changed === true || p.inventory_correct === true
    );
    if (products.length === 0)
      return notificationError("No hay cambios realizados en los productos");

    this.sendProductsForUpdate(products);
  };

  sendProductsForUpdate = (products) => {
    this.props.updateProductsLocalInventory(
      { products },
      (success, message) => {
        if (!success)
          return notificationError(
            "Ocurrio un error, por favor comuniquese con desarrollo para solucionarlo"
          );

        this.updateProductsBaseExistencia(products);
        showToast(message);
        this.changed_products = false;
      }
    );
  };

  updateProductsBaseExistencia = (arrayProducts) => {
    const { products } = this.state;
    arrayProducts.forEach((p) => {
      const index = this.getProductIndex(p.id);
      if (index < 0) return;

      products[index].base_existencia = p.existencia;
      products[index].changed = false;
      products[index].inventory_correct = false;
    });
    this.setState({
      products,
    });
  };

  getChangedLabel = (product) => {
    if (product.existencia > product.base_existencia) {
      return (
        <span className="added-value">
          + {product.existencia - product.base_existencia}
        </span>
      );
    } else if (product.existencia < product.base_existencia) {
      return (
        <span className="minus-value">
          - {product.base_existencia - product.existencia}
        </span>
      );
    }
    return <span>NA</span>;
  };

  getProductsContent = () => {
    const { loading } = this.props.product;
    const { products } = this.state;
    if (loading && !this.is_searching_product) {
      return (
        <div className="row">
          <div className="col">
            <Spinner fullWidth={true} />
          </div>
        </div>
      );
    }
    if (products.length === 0 && !this.is_searching_product) {
      return (
        <div className="row">
          <div className="col s12">
            <EmptyIcon message="No hay productos para esta ubicacion" />
          </div>
        </div>
      );
    }
    if (this.state.mobile_version) {
      return (
        <div className="row">
          <div className="col s12">
            {products.map((p) => (
              <ProductCardInventory
                product={p}
                key={uuid()}
                onChangeTextInput={this.onChangeProductInventory}
                onDeleteProduct={this.onRemoveProductClick}
                onAddInventory={this.onAddInventoryUnity}
                onRemoveInventory={this.onRemoveInventoryUnity}
                onChangeCheckInput={this.onChangeCheckInput}
                onSaveSingleProductClick={this.onSaveSingleProductClick}
              />
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col s12">
          <table className="striped">
            <thead>
              <tr>
                <th></th>
                <th>Codigo</th>
                <th>Ubicacion</th>
                <th>Nombre</th>
                <th>Cambios</th>
                <th style={{ maxWidth: "2rem" }}>Existencia</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p) => (
                <tr key={uuid()}>
                  <td>
                    <ButtonField
                      icon="delete"
                      text=""
                      className="btn btn-icon red"
                      onClick={this.onRemoveProductClick.bind(this, p)}
                    />
                    <ButtonField
                      icon="save"
                      text=""
                      className="btn btn-icon"
                      onClick={this.onSaveSingleProductClick.bind(this, p)}
                    />
                  </td>
                  <td>{p.codigo_barra}</td>
                  <td>{p.ubicacion}</td>
                  <td>{p.nombre}</td>
                  <td>{this.getChangedLabel(p)}</td>
                  <td>
                    <TextInputField
                      type="number"
                      id={p.id}
                      value={p.existencia}
                      onchange={this.onChangeProductInventory.bind(this, p)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  getFilterOptions = () => {
    const { search_ubication, mobile_version } = this.state;
    return (
      <>
        <div className="row" style={{ marginBottom: "0" }}>
          <div className="col text-button-col">
            <TextInputField
              value={search_ubication}
              input_size="0"
              id="search_ubication"
              placeholder="Ubicacion"
              onchange={this.onChangeTextInput}
            />
            <div className="btn-2-center">
              <ButtonField
                icon="search"
                text=""
                className="btn-small btn-primary btn-icon"
                onClick={this.onSearchUbication}
              />
              <ButtonField
                icon="add"
                text=""
                className="btn-small btn-primary btn-icon"
                onClick={this.onAddProductClick}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <CheckInputField
              label="Version para telefonos"
              checked={mobile_version}
              id="mobile_version"
              onchange={this.onChangeCheckField}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        <NewNavbar active_nav="PRODUCTOS">
          <div
            className="nav-wrapper"
            style={{
              overflow: "hidden",
            }}
          >
            <a href="#!" className="brand-logo">
              Actualizacion de inventario
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <ButtonField
                  icon="save"
                  text=""
                  className="btn transparent btn-icon btn-icon-nav"
                  onClick={this.onSaveClick}
                />
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">
            <div className="col s12">
              {this.getFilterOptions()}
              {this.getProductsContent()}
            </div>
          </div>
        </main>

        <SearchProductAndShowInfo
          onSelectProduct={this.onAddProductToArray}
          onCancelSelectProduct={this.onCancelSelectProduct}
        />
      </React.Fragment>
    );
  }
}

ProductsInventory.propTypes = {
  product: PropTypes.object.isRequired,
  searchProductsByUbication: PropTypes.func.isRequired,
  cleanProductsReducer: PropTypes.func.isRequired,
  updateProductsLocalInventory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, {
  searchProductsByUbication,
  cleanProductsReducer,
  updateProductsLocalInventory,
})(ProductsInventory);
