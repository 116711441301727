import axios from "axios";

import { configUserFromResponse } from "./UserActions";
import { handleError } from "./errorActions";
import { API_URL } from "../config";

import {
  KARDEX_LOADING,
  KARDEX_END_LOADING,
  KARDEX_GET_PRODUCTS,
  KARDEX_CLEAR_PRODUCTS_STATE,
} from "./types";

export const getKardexResults = (data) => (dispatch) => {
  dispatch(kardexLoadingObject());
  axios
    .post(`${API_URL}/kardex/get_kardex`, data)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      console.log({
        type: KARDEX_GET_PRODUCTS,
        payload: response.data,
        response,
      });
      dispatch({
        type: KARDEX_GET_PRODUCTS,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, KARDEX_END_LOADING));
};

export const kardexClear = () => (dispatch) => {
  dispatch({
    type: KARDEX_CLEAR_PRODUCTS_STATE,
  });
};

export const kardexLoadingObject = () => {
  return {
    type: KARDEX_LOADING,
  };
};

export const kardexEndLoadingObject = () => {
  return {
    type: KARDEX_END_LOADING,
  };
};
