import React from "react";
import PropTypes from "prop-types";

import ButtonField from "./ButtonField";
import TextInputField from "./TextInputField";
import CheckInputField from "./CheckInputField";

function ProductCardInventory({
  product,
  onDeleteProduct,
  onAddInventory,
  onRemoveInventory,
  onChangeTextInput,
  onSaveSingleProductClick,
  onChangeCheckInput,
}) {
  const { nombre, ubicacion, codigo_barra, existencia, id, base_existencia } =
    product;
  let changedLabel = <span>Cambios: NA</span>;
  if (existencia > base_existencia) {
    changedLabel = (
      <span>
        Cambios:{" "}
        <span className="added-value bold">
          + {existencia - base_existencia}
        </span>
      </span>
    );
  } else if (existencia < base_existencia) {
    changedLabel = (
      <span>
        Cambios:{" "}
        <span className="minus-value bold">
          - {base_existencia - existencia}
        </span>
      </span>
    );
  }
  return (
    <div className="card">
      <div className="card-content">
        <div className="card-title card-title-action">
          <span className="bold card-title-normal">{nombre}</span>

          <ButtonField
            onClick={onDeleteProduct.bind(this, product)}
            className="btn btn-icon red"
            text=""
            icon="delete"
          />
        </div>
        <div>
          <div>
            Codigo: <span className="bold">{codigo_barra}</span>
          </div>
          <div>
            Ubicacion: <span className="bold">{ubicacion}</span>
          </div>
          <div>{changedLabel}</div>
        </div>
        <div
          style={{
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <CheckInputField
            id={`check_product_inventory_${product.id}`}
            label="El inventario concuerda con la base de datos"
            onchange={onChangeCheckInput.bind(this, product)}
            checked={product.inventory_correct}
            useCol={false}
            className="check-label-black"
          />
        </div>
        <div className="number-add-remove">
          <ButtonField
            onClick={onRemoveInventory.bind(this, product)}
            className="btn btn-icon"
            text=""
            icon="remove"
          />

          <TextInputField
            value={existencia}
            input_size="0"
            id={id}
            onchange={onChangeTextInput.bind(this, product)}
          />

          <ButtonField
            onClick={onAddInventory.bind(this, product)}
            className="btn btn-icon"
            text=""
            icon="add"
          />
        </div>
        <div>
          <ButtonField
            className="btn w-100"
            text="Guardar"
            onClick={onSaveSingleProductClick.bind(this, product)}
          />
        </div>
      </div>
    </div>
  );
}

ProductCardInventory.propTypes = {
  product: PropTypes.object.isRequired,
  onDeleteProduct: PropTypes.func.isRequired,
  onAddInventory: PropTypes.func.isRequired,
  onRemoveInventory: PropTypes.func.isRequired,
  onChangeTextInput: PropTypes.func.isRequired,
  onSaveSingleProductClick: PropTypes.func.isRequired,
  onChangeCheckInput: PropTypes.func.isRequired,
};

export default ProductCardInventory;
