import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getModalInstanceById } from "../../../utils/MaterialFunctions";

import TextInputField from "../../common/TextInputField";
import CheckInputField from "../../common/CheckInputField";
import Spinner from "../../common/Spinner";

import { createAndGetClient } from "../../../actions/clientActions";

class NewClientModal extends Component {
  state = {
    nombre: "",
    rtn: "",
    correo: "",
    telefono: "",
    codigo: "",
    es_empresa: false,
    errors: {},
  };

  modal_id = "modal_create_client";

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChangeTextInput = (e) => {
    if (e.target.name === "es_empresa") {
      const value = this.state.es_empresa ? false : true;
      return this.setState({ [e.target.name]: value });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  clearState = () => {
    this.setState({
      nombre: "",
      rtn: "",
      correo: "",
      telefono: "",
      codigo: "",
      es_empresa: false,
    });
  };

  onClickInClient = (client) => {
    this.clearState();
    if (this.props.onSelectClient) {
      this.props.onSelectClient(client);
    }
    getModalInstanceById(this.modal_id).close();
  };

  onCreateClient = () => {
    const { nombre, rtn, correo, telefono, codigo, imagen, es_empresa } =
      this.state;
    this.props.createAndGetClient(
      {
        nombre,
        rtn,
        correo,
        telefono,
        codigo,
        imagen,
        es_empresa,
      },
      (newClient) => {
        this.onClickInClient(newClient);
      }
    );
  };

  render() {
    const {
      nombre,
      rtn,
      correo,
      telefono,
      codigo,
      es_empresa,
      errors: { nombre_error },
    } = this.state;
    const { loading } = this.props.client;
    return (
      <div className="modal modal-fixed-footer" id={this.modal_id}>
        <div className="modal-content">
          <div className="card ">
            <div className="card-content">
              <h5>Nuevo cliente</h5>
              <div className="row">
                <div className="col s12 m12">
                  <div className="row">
                    <TextInputField
                      id="nombre"
                      label="Nombre"
                      input_size="s6"
                      onchange={this.onChangeTextInput}
                      value={nombre}
                      error={nombre_error}
                    />
                    <TextInputField
                      id="rtn"
                      label="RTN"
                      input_size="s6"
                      onchange={this.onChangeTextInput}
                      value={rtn}
                    />
                  </div>
                  <div className="row">
                    <TextInputField
                      id="correo"
                      type="email"
                      label="Correo"
                      input_size="s6"
                      onchange={this.onChangeTextInput}
                      value={correo}
                    />
                    <TextInputField
                      id="telefono"
                      label="Telefono"
                      input_size="s6"
                      onchange={this.onChangeTextInput}
                      value={telefono}
                    />
                  </div>
                  <div className="row">
                    <TextInputField
                      id="codigo"
                      label="Codigo"
                      input_size="s6"
                      onchange={this.onChangeTextInput}
                      value={codigo}
                    />
                    <CheckInputField
                      id="es_empresa"
                      checked={es_empresa}
                      onchange={this.onChangeTextInput}
                      label="Es empresa"
                    />
                  </div>

                  {loading && (
                    <div className="row">
                      <Spinner fullWidth={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <a
            href="#!"
            className="modal-close waves-effect waves-green btn-flat left"
            onClick={this.onHideModal}
          >
            Cerrar
          </a>
          <a
            href="#!"
            className="waves-effect waves-green btn"
            onClick={this.onCreateClient}
          >
            Crear
          </a>
        </div>
      </div>
    );
  }
}

NewClientModal.propTypes = {
  client: PropTypes.object.isRequired,
  onSelectClient: PropTypes.func,
  createAndGetClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  client: state.client,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createAndGetClient,
})(NewClientModal);
