import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  configSelectInputFields,
  getModalInstanceById,
  showModalIfSearchPage,
} from "../../../utils/MaterialFunctions";
import { isEnterKey } from "../../../utils/keyActions";
import {
  getBrands,
  brandsToSelectOptions,
} from "../../../actions/brandActions";
import {
  getVehicles,
  vehiclesToSelectOptions,
} from "../../../actions/vehicleActions";
import {
  getLocals,
  localsToSelectOptions,
} from "../../../actions/LocalActions";

import {
  getProducts,
  searchProduct,
  cleanProducts,
} from "../../../actions/productActions";

import TextInputField from "../../common/TextInputField";
import SelectInputField from "../../common/SelectInputField";

let need_config_selects = false;
class SearchProductModel extends Component {
  state = {
    field: "",
    id_marca: "0",
    id_vehiculo: "0",
    id_local: "0",
    status: "0",
    status_options: [
      {
        value: "0",
        label: "Todos",
      },
      {
        value: "ST_ORDERED",
        label: "Pendiente de entrega",
      },
      {
        value: "ST_INACTIVE",
        label: "Inactivo",
      },
    ],
    inventario_min: "-1",
    inventario_max: "-1",
  };

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  componentDidMount() {
    this.props.getBrands();
    this.props.getVehicles();
    if (this.props.is_admin) {
      this.props.getLocals();
    }
    showModalIfSearchPage("modal_search_producto");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.brand.brands || nextProps.vehicle.vehicles) {
      need_config_selects = true;
    }
  }

  componentDidUpdate() {
    if (need_config_selects) {
      configSelectInputFields();
      need_config_selects = false;
    }
  }

  closeModal = (endSearching = false) => {
    getModalInstanceById("modal_search_producto").close();
    if (endSearching && typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  };

  onKeyUpInput = (e) => {
    if (isEnterKey(e)) {
      this.onSearchProduct();
    }
  };

  onSearchProduct = () => {
    this.closeModal();
    let {
      field,
      id_marca,
      id_vehiculo,
      inventario_min,
      inventario_max,
      id_local,
    } = this.state;
    if (id_marca === "0") {
      id_marca = null;
    }
    if (id_vehiculo === "0") {
      id_vehiculo = null;
    }
    if (inventario_min === "-1") {
      inventario_min = null;
    }
    if (inventario_max === "-1") {
      inventario_max = null;
    }
    if (!this.props.is_admin || id_local === "0") {
      id_local = null;
    }
    this.props.searchProduct({
      field,
      id_marca,
      id_tipo_vehiculo: id_vehiculo,
      inventario_min,
      inventario_max,
      id_local,
      show_all: true,
    });
  };

  onGetAllClick = () => {
    this.closeModal(true);
    this.props.cleanProducts();
    this.props.getProducts(0);
  };
  render() {
    const {
      field,
      id_marca,
      id_local,
      id_vehiculo,
      status,
      status_options,
      inventario_max,
      inventario_min,
    } = this.state;
    const {
      brand: { brands },
      vehicle: { vehicles },
      local: { locals },
      is_admin,
    } = this.props;
    const brandOptions = brandsToSelectOptions(brands);
    const vehicleOptions = vehiclesToSelectOptions(vehicles);
    const localOptions = localsToSelectOptions(locals);
    return (
      <div className="modal modal-fixed-footer" id="modal_search_producto">
        <div className="modal-content">
          <h6>Buscar producto</h6>
          <div className="row">
            <TextInputField
              id="field"
              label="Nombre del producto o codigo de barra"
              value={field}
              onchange={this.onChangeTextInput}
              onKeyUp={this.onKeyUpInput}
            />
          </div>
          <div className="row">
            <TextInputField
              input_size="s12 m6"
              id="inventario_min"
              label="Inventario mayor a"
              value={inventario_min}
              type="number"
              onchange={this.onChangeTextInput}
            />

            <TextInputField
              input_size="s12 m6"
              id="inventario_max"
              label="Inventario menor a"
              value={inventario_max}
              type="number"
              onchange={this.onChangeTextInput}
            />
          </div>

          {is_admin && (
            <div className="row">
              <SelectInputField
                id="id_local"
                label="Local"
                value={id_local}
                onchange={this.onChangeTextInput}
                options={localOptions}
              />
            </div>
          )}

          <div className="row">
            <SelectInputField
              id="status"
              label="Estatus"
              value={status}
              onchange={this.onChangeTextInput}
              options={status_options}
            />
          </div>

          <div className="row">
            <SelectInputField
              id="id_marca"
              label="Marca"
              value={id_marca}
              onchange={this.onChangeTextInput}
              options={brandOptions}
            />
          </div>

          <div className="row">
            <SelectInputField
              id="id_vehiculo"
              label="Tipo de vehiculo"
              value={id_vehiculo}
              onchange={this.onChangeTextInput}
              options={vehicleOptions}
            />
          </div>
        </div>
        <div className="modal-footer">
          <a
            href="#!"
            className="btn-flat left"
            onClick={this.closeModal.bind(this, true)}
          >
            Cerrar
          </a>
          <a href="#!" className="btn ml-1 left" onClick={this.onGetAllClick}>
            Obtener todo
          </a>
          <a href="#!" className="right btn" onClick={this.onSearchProduct}>
            Buscar
          </a>
        </div>
      </div>
    );
  }
}

SearchProductModel.propTypes = {
  brand: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  local: PropTypes.object.isRequired,
  getBrands: PropTypes.func.isRequired,
  getVehicles: PropTypes.func.isRequired,
  getLocals: PropTypes.func.isRequired,
  is_admin: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

SearchProductModel.defaultProps = {
  is_admin: false,
};

const mapStateToProps = (state) => ({
  brand: state.brand,
  vehicle: state.vehicle,
  local: state.local,
});

export default connect(mapStateToProps, {
  getBrands,
  getVehicles,
  getProducts,
  searchProduct,
  getLocals,
  cleanProducts,
})(SearchProductModel);
