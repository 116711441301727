import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import uuid from "uuid";

import TextInputField from "../../common/TextInputField";
import Spinner from "../../common/Spinner";
import EmptyIcon from "../../common/EmptyIcon";

import { searchProduct } from "../../../actions/productActions";
import { getModalInstanceById } from "../../../utils/MaterialFunctions";

/*
Este componente es util en el area de Ventas y Cotizaciones
puede buscar, mostrar y brindar un metodo para seleccionar productos
*/

class SearchProductAndShowInfo extends Component {
  state = {
    field: "",
    typing: false,
    typingTimeout: 0,
    searching: false,
  };

  onChangeTextInput = (e) => {
    if (this.state.typingTimeout) {
      this.setState({ searching: true });
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      field: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        if (this.state.field.trim() !== "") {
          let searchData = {
            field: this.state.field,
          };
          if (this.props.provider_id && this.props.provider_id !== "0") {
            searchData.id_proveedor = this.props.provider_id;
          }
          this.props.searchProduct(searchData);
        } else {
          this.setState({ searching: false });
        }
      }, 1500),
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.product.products) {
      this.setState({
        searching: false,
      });
    }
  }

  onHideModal = () => {
    if (this.props.onHide) {
      this.props.onHide();
    }
    if (this.props.onCancelSelectProduct) {
      this.props.onCancelSelectProduct();
    }
  };

  onSelectProduct = (product) => {
    if (this.props.onSelectProduct) {
      const selectedProduct = JSON.parse(JSON.stringify(product));
      this.props.onSelectProduct(selectedProduct);
    }
    getModalInstanceById(this.props.modal_id).close();
    this.onHideModal();
  };

  render() {
    const { field, searching } = this.state;
    const { products, loading } = this.props.product;
    const { show_all_info } = this.props;
    let productInfo;

    if (searching || loading) {
      productInfo = <Spinner fullWidth />;
    } else if (products && products.length > 0) {
      productInfo = (
        <table>
          <thead>
            <tr>
              {show_all_info && <th>ID</th>}
              <th>Codigo</th>
              <th>Nombre</th>
              <th>Precio</th>
              <th>Inventario</th>
              {show_all_info && (
                <React.Fragment>
                  <th>Marca</th>
                  <th>Tipo de vehiculo</th>
                </React.Fragment>
              )}
            </tr>
          </thead>

          <tbody>
            {products.map((product) => (
              <tr
                key={uuid()}
                className="cursor-pointer"
                onClick={this.onSelectProduct.bind(this, product)}
              >
                {show_all_info && <td>{product.id}</td>}
                <td>{product.codigo_barra}</td>
                <td>{product.nombre}</td>
                <td>{product.precio}</td>
                <td>{product.existencia ? product.existencia : 0}</td>
                {show_all_info && (
                  <React.Fragment>
                    <td>{product.marca && product.marca.nombre}</td>
                    <td>
                      {product.tipo_vehiculo && product.tipo_vehiculo.nombre}
                    </td>
                  </React.Fragment>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      productInfo = <EmptyIcon message="No se encontro ningun producto" />;
    }
    return (
      <div className="modal" id={this.props.modal_id}>
        <div className="modal-content">
          <div className="row">
            <TextInputField
              id="field"
              onchange={this.onChangeTextInput}
              value={field}
            />
          </div>

          {productInfo}
        </div>
        <div className="modal-footer">
          <a
            href="#!"
            className="btn-flat modal-close"
            onClick={this.onHideModal}
          >
            Cerrar
          </a>
        </div>
      </div>
    );
  }
}

SearchProductAndShowInfo.propTypes = {
  show_all_info: PropTypes.bool.isRequired,
  searchProduct: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  onSelectProduct: PropTypes.func,
  onCancelSelectProduct: PropTypes.func,
  onHide: PropTypes.func,
  modal_id: PropTypes.string.isRequired,
  provider_id: PropTypes.string,
};

SearchProductAndShowInfo.defaultProps = {
  show_all_info: false,
  provider_id: null,
  modal_id: "search_product_and_show_info",
};

const mapStateToProps = (state) => ({
  product: state.product,
});
export default connect(mapStateToProps, {
  searchProduct,
})(SearchProductAndShowInfo);
