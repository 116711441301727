import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getNumberFormatted } from "../../utils/stringUtils";
import { getDaysDiffDate } from "../../utils/dateFormat";

const getDaysCredit = (fecha_creado, dias_credito) => {
  return dias_credito - getDaysDiffDate(new Date(fecha_creado), new Date());
};

const getStatus = status => {
  if (status !== "ST_VENDIDA") return null;

  return (
    <span className="d-block">
      Estatus: Vendida
      <div
        className="circle-element green right bordered"
        style={{ width: "20px", height: "20px" }}
      />
    </span>
  );
};

const SellCard = props => {
  const {
    sell: {
      id,
      codigo,
      fecha_creado,
      con_factura,
      no_factura,
      total,
      usuario_creador,
      cliente,
      metodo_pago,
      credito_pagado,
      fecha_credito_pagado,
      dias_credito,
      status
    },
    is_admin,
    es_cotizacion
  } = props;
  let days_credit_left = getDaysCredit(fecha_creado, dias_credito);
  return (
    <div className="card hoverable">
      <Link
        to={`${is_admin ? "/admin" : ""}/${es_cotizacion ? "cotizaciones" : "ventas"
          }/${id}`}
      >
        <div className="card-content">
          <div className="d-block">
            <span>ID: {id}</span>
            <span className="right">{fecha_creado}</span>
          </div>
          {codigo && (
            <div className="d-block">
              <span>Codigo: {codigo}</span>
            </div>
          )}
          {no_factura && (
            <div className="d-block">
              <span>No Factura: {no_factura}</span>
            </div>
          )}
          {cliente && (
            <div className="d-block">
              <span>
                Cliente: {cliente.nombre}{" "}
                {cliente.rtn ? ` - ${cliente.rtn}` : ""}
              </span>
            </div>
          )}
          {metodo_pago === "credito" && credito_pagado === false && (
            <div className="d-block">
              <span>
                Credito pendiente:{" "}
                {days_credit_left >= 0
                  ? `${days_credit_left} Dias restantes`
                  : `${days_credit_left * -1} Dias de retraso`}
              </span>
              <div
                className={`circle-element ${days_credit_left > 0 ? "deep-orange" : "red darken-2"
                  } right bordered`}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          )}
          {fecha_credito_pagado && (
            <div className="d-block">
              <span>Credito pagado el: {fecha_credito_pagado}</span>
            </div>
          )}
          <div className="d-block">
            <span>
              Vendedor: {usuario_creador.id} -{" "}
              <span> {usuario_creador.nombre}</span>
            </span>
          </div>
          <div className="d-block">
            {!es_cotizacion && <span>{con_factura ? "C/F" : "S/F"}</span>}

            <span className={`${es_cotizacion ? "" : "right"}`}>
              Total: Lps {getNumberFormatted(total)}
            </span>
          </div>

          {es_cotizacion && getStatus(status)}
        </div>
      </Link>
    </div>
  );
};

SellCard.propTypes = {
  sell: PropTypes.object.isRequired,
  is_admin: PropTypes.bool.isRequired,
  es_cotizacion: PropTypes.bool.isRequired
};

export default SellCard;
