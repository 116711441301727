// Este archivo almacena todas las funciones necesarias
// para poder enviar consultas a PRODUCTS
import axios from "axios";

import { clearErrors, handleError } from "./errorActions";

import { configUserFromResponse } from "./UserActions";

import {
  GET_PRODUCT,
  GET_PRODUCTS,
  PRODUCT_LOADING,
  PRODUCT_END_LOADING,
  CLEAR_PRODUCTS_STATE,
} from "./types";

import { API_URL } from "../config";

export const getProducts = (int_offset) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .get(`${API_URL}/products/get/${int_offset}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const searchProductsToImport = (jsonData) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/products/find_to_import_multiple`, jsonData)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const getProductsToExport = () => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .get(`${API_URL}/products/export`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
        force: true,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const getProductsToExportWithParams = (data) => (dispatch) => {
  dispatch(productLoadingObject());

  axios
    .post(`${API_URL}/products/export_with_params`, data)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
        force: true,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const searchProduct = (field) => (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCTS_STATE,
  });
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/products/search`, field)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
        force: true,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const searchProductsByUbication = (field) => (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCTS_STATE,
  });
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/products/search_with_location`, {
      field,
    })
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data,
        force: true,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const getProductById = (id) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .get(`${API_URL}/products/get_one/${id}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCT,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const getProductByCBForSell = (productData) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/products/get_one_fsell`, productData)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PRODUCT,
        payload: {},
      });
      handleError(err, dispatch);
    });
};

export const getProductClientsPrices = (id, callback) => async (dispatch) => {
  let data = [];
  let error = null;
  try {
    const serverResponse = await axios.get(
      `${API_URL}/products/get_client_sales/${id}`
    );
    const response = serverResponse.data;
    configUserFromResponse(response, dispatch);
    data = response.data;
  } catch (err) {
    handleError(err, dispatch);
  }
  if (callback) {
    callback(error, data);
  }
};

export const addNewProduct =
  (newProductData, history, new_url) => (dispatch) => {
    dispatch(productLoadingObject());
    axios
      .post(`${API_URL}/products/add`, newProductData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
      .then((res) => {
        dispatch(clearErrors());
        const response = res.data;
        configUserFromResponse(response, dispatch);
        dispatch({
          type: GET_PRODUCT,
          payload: response.data,
        });
        history.push(`${new_url}/${response.data.id}`);
      })
      .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
  };

export const addMultiple = (productsData) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/products/add_multiple`, productsData)
    .then((res) => {
      dispatch(clearErrors());
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: PRODUCT_END_LOADING,
      });
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const updateProductById = (id, newData) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/products/update/${id}`, newData, {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })
    .then((res) => {
      dispatch(clearErrors());
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCT,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const updateProductsLocalInventory =
  (data, onCompleteFunction) => (dispatch) => {
    dispatch(productLoadingObject());
    axios
      .post(`${API_URL}/products/update_products_inventory`, data)
      .then((res) => {
        dispatch(clearErrors());
        const response = res.data;
        configUserFromResponse(response, dispatch);
        dispatch({
          type: PRODUCT_END_LOADING,
        });
        onCompleteFunction(true, response.data.message);
      })
      .catch((err) => {
        handleError(err, dispatch, PRODUCT_END_LOADING);
        onCompleteFunction(false);
      });
  };

export const freezeProduct = (id, freezze) => async (dispatch) => {
  try {
    const serverResponse = await axios.put(
      `${API_URL}/products/set_congelado/${id}/${freezze}`
    );
    dispatch(clearErrors());
    const response = serverResponse.data;
    configUserFromResponse(response, dispatch);
    dispatch({
      type: GET_PRODUCT,
      payload: response.data,
    });
  } catch (err) {
    handleError(err, dispatch, PRODUCT_END_LOADING);
  }
};

export const deleteProductById = (id, history, new_url) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .delete(`${API_URL}/products/delete/${id}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({ type: PRODUCT_END_LOADING });
      history.push(new_url);
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

// Reports
export const getProductsReport = (jsonData) => (dispatch) => {
  dispatch(productLoadingObject());
  axios
    .post(`${API_URL}/reports/get_products`, jsonData)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_PRODUCT,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, PRODUCT_END_LOADING));
};

export const cleanProductsReducer = () => (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCTS_STATE,
  });
};

// Helper objects
export const productLoadingObject = () => {
  return {
    type: PRODUCT_LOADING,
  };
};

export const productEndLoadingObject = () => {
  return {
    type: PRODUCT_END_LOADING,
  };
};

export const cleanProducts = () => (dispatch) => {
  dispatch({
    type: GET_PRODUCTS,
    payload: [],
    force: true,
  });
};

export const cleanProduct = () => (dispatch) => {
  dispatch({
    type: GET_PRODUCT,
    payload: {},
  });
};
