/**
 * Retorna los elementos y paginacion de una respuesta endpoint
 */
export function getResponsePayload(action) {
  let elements = [];
  const pagination = action?.payload?.pagination || {};

  if (Array.isArray(action?.payload)) {
    elements = action.payload;
  } else if (Array.isArray(action?.payload?.rows)) {
    elements = action.payload.rows;
  }

  return {
    elements,
    pagination,
  };
}
