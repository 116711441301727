import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  configSelectInputFields,
  updateTextFields,
  notificationError,
} from "../../utils/MaterialFunctions";

import { jsonToExcel } from "../../utils/jsonExcel";

import { getProductsReport } from "../../actions/productActions";
import { getLocals, localsToSelectOptions } from "../../actions/LocalActions";
import {
  getClients,
  clientsToSelectOptions,
} from "../../actions/clientActions";
import { getCurrentDateToInput } from "../../utils/dateFormat";
import TextInputField from "./TextInputField";
import SelectInputField from "./SelectInputField";

class ProductReportOptions extends Component {
  state = {
    id_local: "0",
    cliente: "0",
    limite_productos: "20",
    fecha_inicio: "",
    fecha_final: "",
    tipo_reporte: "1",
    tipos_reporte: [
      { value: "1", label: "Mas vendidos", tipo: "mas_vendidos" },
      { value: "2", label: "Menos vendidos", tipo: "menos_vendidos" },
    ],
    clientes: [],
    need_config_selects: false,
  };
  need_update_date_inputs = false;

  componentDidMount() {
    let fecha_actual = getCurrentDateToInput();
    this.setState({
      fecha_final: fecha_actual,
      fecha_inicio: fecha_actual,
    });
    this.need_update_date_inputs = true;
    if (this.props.is_admin) {
      this.props.getLocals();
    }

    this.props.getClients();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.local.locals.length > 0) {
      this.setState({ need_config_selects: true });
    }
    if (
      nextProps.client.clients &&
      this.state.clientes.length === 0 &&
      nextProps.client.clients.length > 0
    ) {
      this.setState({
        clientes: clientsToSelectOptions(nextProps.client.clients),
        need_config_selects: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.need_config_selects) {
      configSelectInputFields();
      this.setState({
        need_config_selects: false,
      });
    }

    if (this.need_update_date_inputs) {
      updateTextFields();
      this.need_update_date_inputs = false;
    }
  }

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onGetReport = () => {
    let {
      id_local,
      cliente,
      limite_productos,
      fecha_inicio,
      fecha_final,
      tipo_reporte,
      tipos_reporte,
    } = this.state;
    if (id_local === "0") {
      id_local = null;
    }
    if (cliente === "0") {
      cliente = null;
    }
    tipo_reporte = tipos_reporte.find(
      (tipo) => tipo.value === tipo_reporte
    ).tipo;
    this.props.getProductsReport({
      id_local,
      id_cliente: cliente,
      limite: limite_productos,
      fecha_inicio,
      fecha_final,
      tipo_reporte,
    });
  };

  onPrintToExcelClick = () => {
    const products = this.props.product.product.productos;
    if (products.length === 0) {
      return notificationError("No hay productos para exportar");
    }

    const date = new Date();
    jsonToExcel(
      products,
      {
        id_producto: "id_producto",
        codigo_barra: "codigo_barra",
        nombre: "nombre",
        cantidad: "cantidad",
        inventario: "inventario",
      },
      `rimeim_reporte_productos_${date.getFullYear()}_${
        date.getMonth() + 1
      }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}`
    );
  };

  render() {
    const { is_admin } = this.props;
    let localOptions = [];
    const {
      id_local,
      limite_productos,
      fecha_final,
      fecha_inicio,
      tipo_reporte,
      tipos_reporte,
      cliente,
      clientes,
    } = this.state;

    if (is_admin) {
      localOptions = localsToSelectOptions(this.props.local.locals);
    }
    return (
      <div className="card">
        <div className="card-content">
          <div className="row">
            {is_admin && (
              <SelectInputField
                id="id_local"
                label="Local"
                input_size="s12 m6"
                value={id_local}
                onchange={this.onChangeTextInput}
                options={localOptions}
              />
            )}

            <SelectInputField
              id="tipo_reporte"
              input_size="s12 m6"
              label="Tipo de reporte"
              value={tipo_reporte}
              options={tipos_reporte}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <SelectInputField
              id="cliente"
              input_size="s12 m6"
              label="Cliente"
              value={cliente}
              options={clientes}
              onchange={this.onChangeTextInput}
            />

            <TextInputField
              id="limite_productos"
              input_size="s12 m6"
              label="Numero de resultados"
              type="number"
              value={limite_productos}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <TextInputField
              id="fecha_inicio"
              type="date"
              input_size="s12 m6"
              label="Fecha de inicio"
              value={fecha_inicio}
              onchange={this.onChangeTextInput}
            />

            <TextInputField
              id="fecha_final"
              type="date"
              input_size="s12 m6"
              label="Fecha final"
              value={fecha_final}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <div className="col s12">
              <div
                style={{
                  display: "grid",
                  columnGap: "1rem",
                  rowGap: "1rem",
                  gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))",
                }}
              >
                <button className="btn" onClick={this.onGetReport}>
                  Obtener reporte
                </button>
                <button className="btn" onClick={this.onPrintToExcelClick}>
                  Exportar como Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProductReportOptions.propTypes = {
  local: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  is_admin: PropTypes.bool.isRequired,
  getLocals: PropTypes.func.isRequired,
  getProductsReport: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
};

ProductReportOptions.defaultProps = {
  is_admin: false,
};

const mapStateToProps = (state) => ({
  local: state.local,
  client: state.client,
  product: state.product,
});

export default connect(mapStateToProps, {
  getLocals,
  getProductsReport,
  getClients,
})(ProductReportOptions);
