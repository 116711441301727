import {
  DEVOLUTION_LOADING,
  DEVOLUTION_END_LOADING,
  GET_DEVOLUTION,
  GET_DEVOLUTIONS,
  CLEAR_DEVOLUTIONS_STATE,
} from "../actions/types";
import { getResponsePayload } from "../utils/reducerUtils";

const initialState = {
  loading: false,
  devolutions: [],
  devolution: {},
  pagination: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEVOLUTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DEVOLUTION_END_LOADING:
      return {
        ...state,
        loading: false,
      };

    case GET_DEVOLUTION:
      return {
        ...state,
        loading: false,
        devolution: action.payload,
      };

    case GET_DEVOLUTIONS:
      const data = getResponsePayload(action);
      return {
        ...state,
        loading: false,
        devolutions: data.elements,
        pagination: data.pagination,
      };

    case CLEAR_DEVOLUTIONS_STATE:
      return {
        ...state,
        loading: false,
        devolution: {},
        devolutions: {},
      };

    default:
      return state;
  }
}
