import {
  PRODUCT_LOADING,
  GET_PRODUCT,
  GET_PRODUCTS,
  PRODUCT_END_LOADING,
  CLEAR_PRODUCTS_STATE
} from "../actions/types";

const initialState = {
  loading: false,
  product: {},
  products: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: true
      };

    case PRODUCT_END_LOADING:
      return {
        ...state,
        loading: false
      };

    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false
      };

    case GET_PRODUCTS:
      let new_products = state.products;
      if (new_products.length > 0 && !action.force) {
        action.payload.forEach(prod => new_products.push(prod));
      } else {
        new_products = action.payload;
      }
      return {
        ...state,
        products: new_products,
        loading: false
      };

    case CLEAR_PRODUCTS_STATE:
      return {
        ...state,
        loading: false,
        products: [],
        product: {}
      };

    default:
      return state;
  }
}
