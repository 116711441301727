import React from "react";
import BaseComponent from "../../basecomponents/BaseComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import uuid from "uuid";

import OrderCard from "../../common/OrderCard";
import Spinner from "../../common/Spinner";
import NewNavbar from "../../layout/NewNavbar";

import {
  configMaterialComponents,
  removeMaterialComponents,
} from "../../../utils/MaterialFunctions";

import { getOrders } from "../../../actions/orderActions";
import EmptyIcon from "../../common/EmptyIcon";
import SearchOrderModal from "../../layout/modals/SearchOrder";
import PaginationBar from "../../common/PaginationBar";

class Orders extends BaseComponent {
  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.setPaginationConfig({
      getResultsFunctionName: "getOrders",
      propsObjectName: "orders",
      propsElementsName: "orders",
    });
    if (this.props.orders.orders.length === 0) {
      this.props.getOrders();
    }
  }

  onTablePageClick = (newPage) => {
    this.getResultsForComponent({ currentPage: newPage });
  };

  getElementsContent = () => {
    const {
      orders: { orders, loading, pagination },
      user: { currentLocal },
    } = this.props;

    let ordersContent;

    if (loading) {
      ordersContent = <Spinner fullWidth />;
    } else if (orders.length > 0) {
      ordersContent = (
        <div className="row">
          <div className="col s12">
            {orders.map((order) => (
              <OrderCard
                order={order}
                key={uuid()}
                currentLocal={currentLocal}
              />
            ))}
          </div>
        </div>
      );
    } else {
      return <EmptyIcon message="No hay pedidos" />;
    }

    return (
      <>
        {ordersContent}
        <PaginationBar
          pagination={pagination}
          onPageClick={this.onTablePageClick}
        />
      </>
    );
  };
  render() {
    return (
      <React.Fragment>
        <NewNavbar active_nav="PEDIDOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Pedidos
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a
                  href="#modal_buscar_pedido"
                  className="tooltipped modal-trigger"
                  data-position="left"
                  data-tooltip="Buscar"
                >
                  <i className="material-icons cursor-pointer">search</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">{this.getElementsContent()}</div>
        </main>

        <SearchOrderModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.order,
  user: state.user,
});

export default connect(mapStateToProps, { getOrders })(withRouter(Orders));
