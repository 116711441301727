import { Component } from "react";

export default class BaseComponent extends Component {
  paginationConfig = {
    getResultsFunctionName: "", // Nombre de la funcion que obtiene datos a mostrar
    propsObjectName: "", // Nombre del objeto en Redux que contiene toda la data del componente
    propsElementsName: "", // Nombre de la propiedad que contiene el arreglo de resultados en el componente
  };

  setPaginationConfig = (
    params = {
      getResultsFunctionName: "",
      propsObjectName: "",
      propsElementsName: "",
    }
  ) => {
    this.paginationConfig = params;
  };

  isValidPaginationConfig = () => {
    return (
      (this.paginationConfig.getResultsFunctionName ||
        this.paginationConfig.getResultsFunctionName.trim().length > 0) &&
      (this.paginationConfig.propsObjectName ||
        this.paginationConfig.propsObjectName.length > 0) &&
      (this.paginationConfig.propsElementsName ||
        this.paginationConfig.propsElementsName.length > 0)
    );
  };

  getResultsForComponent(searchData = null) {
    if (!this.isValidPaginationConfig())
      return console.warn(`GRFC | La configuracion de paginacion es invalida`);

    const propsObject = this.props[this.paginationConfig.propsObjectName];
    if (!propsObject) return;

    const getResultsFunction =
      this.props[this.paginationConfig.getResultsFunctionName];
    if (!getResultsFunction)
      return console.warn(`GRFC | Funcion de obtener resultados no existe`);

    if (!searchData) searchData = {};

    if (typeof searchData["currentPage"] === "undefined") {
      const currentPage = propsObject.pagination.currentPage || 1;
      searchData["currentPage"] = currentPage;
    }
    if (typeof searchData["from"] === "undefined") {
      let currentCount = 0;
      if (Array.isArray(propsObject[this.paginationConfig.propsElementsName])) {
        currentCount =
          propsObject[this.paginationConfig.propsElementsName].length;
      }
      searchData["from"] = currentCount;
    }
    getResultsFunction(searchData);
  }
}
