import React, { Component } from "react";
import NewNavbar from "../../layout/NewNavbar";

import {
  configMaterialComponents,
  removeMaterialComponents,
  initModal,
  getModalInstanceById
} from "../../../utils/MaterialFunctions";

import ShowProducts from "../../common/ShowProducts";
import SearchProductModal from "../../layout/modals/SearchProductModel";

class Products extends Component {
  state = {
    searching: false
  };

  modal_search = "modal_search_producto";

  componentWillUnmount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents({ modals: false });
    initModal(this.modal_search);
  }

  onSearchModal = () => {
    getModalInstanceById(this.modal_search).open();
    this.setState({ searching: true });
  };

  onCloseSearchModal = () => {
    this.setState({ searching: false });
  };

  render() {
    const { searching } = this.state;
    return (
      <React.Fragment>
        <NewNavbar active_nav="PRODUCTOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Inventario
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSearchModal}>
                  <i className="material-icons">search</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <ShowProducts searching={searching} />
        </main>
        <SearchProductModal onClose={this.onCloseSearchModal} />
      </React.Fragment>
    );
  }
}

export default Products;
