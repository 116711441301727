import React, { Component } from "react";
import TextInputField from "../../common/TextInputField";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getModalInstanceById,
  showModalIfSearchPage,
} from "../../../utils/MaterialFunctions";
import { getProviders, searchProvider } from "../../../actions/providerActions";
import { isEnterKey } from "../../../utils/keyActions";

class SearchProviderModal extends Component {
  state = {
    field: "",
  };

  componentDidMount() {
    showModalIfSearchPage("modal_buscar_proveedor");
  }

  closeModal = () => {
    getModalInstanceById("modal_buscar_proveedor").close();
  };

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onGetAllClick = () => {
    this.props.getProviders();
  };

  onSearchClick = () => {
    const { field } = this.state;
    this.props.searchProvider({ field });
  };

  onKeyUpInput = (e) => {
    if (isEnterKey(e)) {
      this.onSearchClick();
      this.closeModal();
    }
  };
  render() {
    const { field } = this.state;
    return (
      <div className="modal" id="modal_buscar_proveedor">
        <div className="modal-content">
          <h5>Buscar Proveedor</h5>
          <div className="row">
            <TextInputField
              id="field"
              label="Termino de busqueda"
              value={field}
              onchange={this.onChangeTextInput}
              onKeyUp={this.onKeyUpInput}
            />
          </div>
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close btn-flat left">
            Cerrar
          </a>
          <a
            href="#!"
            className="btn modal-close"
            onClick={this.onGetAllClick}
            style={{ marginRight: "20px" }}
          >
            Obtener todo
          </a>
          <a href="#!" className="modal-close btn" onClick={this.onSearchClick}>
            Buscar
          </a>
        </div>
      </div>
    );
  }
}

SearchProviderModal.propTypes = {
  getProviders: PropTypes.func.isRequired,
  searchProvider: PropTypes.func.isRequired,
};

export default connect(null, { getProviders, searchProvider })(
  SearchProviderModal
);
