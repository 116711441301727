import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import NavbarAdmin from "../../../layout/NewNavbarAdmin"
import uuid from "uuid"

import {
  configMaterialComponents,
  removeMaterialComponents,
  getModalInstanceById,
} from "../../../../utils/MaterialFunctions"

import {
  addMultiple,
  searchProductsToImport,
} from "../../../../actions/productActions"
import { getJsonFromExcel } from "../../../../utils/jsonExcel"

import { fullTrim } from "../../../../utils/stringUtils"

import Spinner from "../../../common/Spinner"
import TextInputField from "../../../common/TextInputField"
import CheckInputField from "../../../common/CheckInputField"
import ProductFromExcelCard from "../../../common/ProductsImportCard"
import InputFile from "../../../common/InputFile"
import isEmpty from "../../../../actions/isEmpty"
import ConfirmationModal from "../../../layout/modals/ConfirmationModal"

class NewProductsFromExcel extends Component {
  state = {
    loading_json: false,
    buscar_en_server: false,
    marcar_cero_como_invalido: false,
    buscando_en_server: false,
    cantidad_minima: "0",
    cantidad_minima_local: "0",
    message: "",
    error: false,
    show_confirmation_modal: false,
    sending_data: false,
    productos: [],
    productos_invalidos: [],
  }

  componentWillMount() {
    removeMaterialComponents()
  }

  componentDidMount() {
    configMaterialComponents()
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.product.loading && this.state.sending_data) {
      if (nextProps.errors && !isEmpty(nextProps.errors)) {
        this.setState({
          message:
            "Algo no ha salido bien en el proceso, verifique el archivo e intente en un momento",
          error: true,
          show_confirmation_modal: true,
          sending_data: false,
        })
      } else {
        this.setState({
          message: "Los productos fueron guardados con exito",
          error: false,
          show_confirmation_modal: true,
          sending_data: false,
        })
      }
    }

    if (
      nextProps.product.products.length > 0 &&
      !nextProps.product.loading &&
      this.state.buscando_en_server
    ) {
      this.recheckInvalidProducts(nextProps.product.products)
    }
  }

  componentDidUpdate() {
    if (this.state.show_confirmation_modal) {
      this.setState({
        show_confirmation_modal: false,
      })
      getModalInstanceById("modal_confirmar_evento").open()
    }
  }

  onChangeTextInput = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeCheckBox = (e) =>
    this.setState({ [e.target.name]: !this.state[e.target.name] })

  onChangeCheckField = (e) => {
    const current_value = !this.state[e.target.name]
    this.setState({ [e.target.name]: current_value })
    if (e.target.name === "buscar_en_server") {
      if (
        this.state.productos_invalidos.length > 0 &&
        current_value &&
        !this.state.buscando_en_server
      ) {
        const productos = this.getInvalidProductsToSearchInServer()
        this.props.searchProductsToImport({
          productos,
        })
        this.setState({
          buscando_en_server: true,
        })
      }
    }
  }

  onChangeExcelFile = (e) => {
    this.setState({
      loading_json: true,
    })
    getJsonFromExcel(e.target.files[0], (json) => {
      let array_response
      try {
        array_response = this.getParsedProducts(json)
        document.getElementById("excel_file").value = null
        this.setState({
          productos: array_response.newProducts,
          productos_invalidos: array_response.newInvalidProducts,
          loading_json: false,
        })
        if (
          array_response.newInvalidProducts.length > 0 &&
          this.state.buscar_en_server
        ) {
          const productos = this.getInvalidProductsToSearchInServer()
          this.props.searchProductsToImport({
            productos,
          })
          this.setState({
            buscando_en_server: true,
          })
        }
      } catch (e) {
        this.setState({
          loading_json: false,
        })
      }
    })
  }

  getParsedProducts = (crudProductos) => {
    const newProducts = []
    const newInvalidProducts = []
    for (let x = 0; x < crudProductos.length; x++) {
      const prod = crudProductos[x]
      if (!prod.hasOwnProperty("cantidad")) {
        prod.cantidad = 0
      }
      if (!prod.hasOwnProperty("precio")) {
        prod.precio = 0
      }
      if (
        !prod.hasOwnProperty("distribucion") &&
        prod.hasOwnProperty("codigo") &&
        !isEmpty(prod.codigo) &&
        prod.hasOwnProperty("local") &&
        !isEmpty(prod.local) &&
        prod.hasOwnProperty("cantidad") &&
        prod.hasOwnProperty("ubicacion") &&
        !isEmpty(prod.ubicacion)
      ) {
        let cantidad = Number.isInteger(prod.cantidad)
          ? prod.cantidad
          : parseInt(prod.cantidad)
        if (cantidad === null || typeof cantidad === "undefined") {
          newInvalidProducts.push(prod)
          continue
        }

        let codigo = fullTrim(prod.codigo)
        if (isEmpty(codigo)) {
          newInvalidProducts.push(prod)
          continue
        }
        let local = `${prod.local}`.replace('"', "").trim()
        let ubicacion = prod.ubicacion.trim()
        let cantidad_minima = prod.cantidad_minima ? prod.cantidad_minima : 0
        let cantidad_minima_local = prod.cantidad_minima_local
          ? prod.cantidad_minima_local
          : 0
        let distribucion_local_obj

        if (!isEmpty(local) && !isEmpty(ubicacion)) {
          distribucion_local_obj = {
            local,
            ubicacion,
            cantidad,
            cantidad_minima_local,
          }
        }

        let precio = Number.isInteger(prod.precio)
          ? prod.precio
          : parseInt(prod.precio)
        if (precio === null || !(precio >= 0) || isNaN(precio)) {
          precio = 0
        }

        const findedIndex = newProducts.findIndex(
          (nprod) => `${nprod.codigo}` === `${codigo}`
        )
        if (findedIndex >= 0) {
          const currentProd = newProducts[findedIndex]
          currentProd.cantidad += cantidad

          if (distribucion_local_obj) {
            const findedLocalIndex = currentProd.distribucion.findIndex(
              (dist) => dist.local === distribucion_local_obj.local
            )

            if (findedLocalIndex >= 0) {
              currentProd.distribucion[findedLocalIndex].cantidad += cantidad
            } else {
              currentProd.distribucion.push(distribucion_local_obj)
            }
          }

          newProducts[findedIndex] = currentProd
        } else {
          let descripcion = prod.descripcion ? prod.descripcion.trim() : ""
          if (isEmpty(descripcion)) {
            newInvalidProducts.push(prod)
            continue
          }

          if (codigo === "8-97287590-LS") {
            console.log(
              `Producto encontrado ${x}, cantidad actual: ${cantidad}`
            )
          }

          const newProd = {
            codigo,
            descripcion,
            cantidad,
            cantidad_minima,
            precio,
            distribucion: [],
          }
          if (distribucion_local_obj) {
            newProd.distribucion.push(distribucion_local_obj)
          }
          newProducts.push(newProd)
        }
      } else {
        if (prod.hasOwnProperty("codigo")) {
          prod.codigo = fullTrim(prod.codigo)
        }
        if (!prod.hasOwnProperty("distribucion")) {
          newInvalidProducts.push(prod)
        } else if (prod.hasOwnProperty("distribucion")) {
          newProducts.push(prod)
        }
      }
    }

    // Segundo Check a productos invalidos
    const index_invalid_products_to_remove = []
    for (let index = 0; index < newInvalidProducts.length; index++) {
      const invalid_prod = newInvalidProducts[index]
      const product_values = this.getProductImportValues(invalid_prod)
      if (product_values) {
        const findedIndex = newProducts.findIndex(
          (p) => `${p.codigo}`.indexOf(`${product_values.codigo}`) >= 0
        )
        if (findedIndex < 0) {
          continue
        }

        const currentProd = newProducts[findedIndex]
        currentProd.cantidad += product_values.cantidad

        if (product_values.distribucion_local_obj) {
          const findedLocalIndex = currentProd.distribucion.findIndex(
            (dist) => dist.local === product_values.distribucion_local_obj.local
          )

          if (findedLocalIndex >= 0) {
            currentProd.distribucion[findedLocalIndex].cantidad +=
              product_values.cantidad
          } else {
            currentProd.distribucion.push(product_values.distribucion_local_obj)
          }
        }
        index_invalid_products_to_remove.push(index)
        newProducts[findedIndex] = currentProd
      }
    }

    index_invalid_products_to_remove.forEach((index) =>
      newInvalidProducts.splice(index, 1)
    )
    return { newProducts, newInvalidProducts }
  }

  getProductImportValues = (prod) => {
    let is_valid = true
    ;["codigo", "cantidad", "local", "ubicacion"].forEach((key) => {
      if (!prod.hasOwnProperty(key) || isEmpty(prod[key])) {
        is_valid = false
      }
    })
    if (!is_valid) {
      return null
    }

    let cantidad = Number.isInteger(prod.cantidad)
      ? prod.cantidad
      : parseInt(prod.cantidad)
    if (cantidad === null || typeof cantidad === "undefined") {
      return null
    }

    let codigo = fullTrim(prod.codigo)
    if (isEmpty(codigo)) {
      return null
    }

    let local = prod.local.replace('"', "").trim()
    let ubicacion = prod.ubicacion.trim()
    let cantidad_minima = prod.cantidad_minima ? prod.cantidad_minima : 0
    let cantidad_minima_local = prod.cantidad_minima_local
      ? prod.cantidad_minima_local
      : 0
    let distribucion_local_obj

    if (!isEmpty(local) && !isEmpty(ubicacion)) {
      distribucion_local_obj = {
        local,
        ubicacion,
        cantidad,
        cantidad_minima_local,
      }
    }

    let precio = Number.isInteger(prod.precio)
      ? prod.precio
      : parseInt(prod.precio)
    if (precio === null || !(precio >= 0) || isNaN(precio)) {
      precio = 0
    }

    return {
      precio,
      distribucion_local_obj,
      cantidad_minima,
      cantidad,
      codigo,
    }
  }

  getInvalidProductsToSearchInServer = () => {
    const {
      productos_invalidos,
      productos,
      marcar_cero_como_invalido,
    } = this.state
    const productos_response = []
    productos_invalidos.forEach((producto) => {
      if (producto.codigo && !isEmpty(producto.codigo)) {
        producto.codigo = fullTrim(producto.codigo)
        productos_response.push({ codigo_barra: `${producto.codigo}` })
      }
    })
    if (marcar_cero_como_invalido) {
      productos
        .filter((prod) => prod.precio === 0)
        .forEach((producto) => {
          if (producto.codigo && !isEmpty(producto.codigo)) {
            producto.codigo = fullTrim(producto.codigo)
            productos_response.push({ codigo_barra: `${producto.codigo}` })
          }
        })
    }
    return productos_response
  }

  recheckInvalidProducts = (products_from_server) => {
    let {
      productos,
      productos_invalidos,
      marcar_cero_como_invalido,
    } = this.state
    products_from_server.forEach((product) => {
      let findedIndex = productos_invalidos.findIndex(
        (p) => `${p.codigo}` === `${product.codigo_barra}`
      )
      if (findedIndex >= 0) {
        if (isEmpty(productos_invalidos[findedIndex].ubicacion)) {
          if (product.distribucion) {
            const findedUbication = product.distribucion.find(
              (d) => d.local.codigo === productos_invalidos[findedIndex].local
            )
            if (findedUbication) {
              productos_invalidos[findedIndex].ubicacion =
                findedUbication.ubicacion
            }
          }
        }
        productos_invalidos[findedIndex].descripcion = product.nombre
        productos_invalidos[findedIndex].precio = product.precio
        productos.push(productos_invalidos[findedIndex])
        productos_invalidos.splice(findedIndex, 1)
      }

      if (marcar_cero_como_invalido) {
        findedIndex = productos.findIndex(
          (p) => `${p.codigo}` === `${product.codigo_barra}`
        )
        if (findedIndex >= 0) {
          productos[findedIndex].precio = product.precio
        }
      }
    })
    productos_invalidos.forEach((prod) => productos.push(prod))
    const updatedValues = this.getParsedProducts(productos)
    this.setState({
      productos: updatedValues.newProducts,
      productos_invalidos: updatedValues.newInvalidProducts,
      buscando_en_server: false,
    })
  }

  onClickApplyChanges = () => {
    const { productos, cantidad_minima, cantidad_minima_local } = this.state
    this.setState({
      loading_json: true,
    })
    productos.forEach((producto) => {
      if (producto.cantidad_minima === 0) {
        producto.cantidad_minima = parseInt(cantidad_minima)
      }
      producto.distribucion.forEach((dist) => {
        if (dist.cantidad_minima_local === 0) {
          dist.cantidad_minima_local = parseInt(cantidad_minima_local)
        }
      })
    })
    this.setState({
      loading_json: false,
    })
  }

  onSaveProducts = () => {
    this.props.addMultiple({ productos: this.state.productos })
    this.setState({ sending_data: true })
  }

  onAcceptClick = () => {
    if (!this.state.error) {
      this.setState({
        productos: [],
      })
    }
  }

  render() {
    const {
      productos,
      productos_invalidos,
      loading_json,
      cantidad_minima,
      cantidad_minima_local,
      buscar_en_server,
      marcar_cero_como_invalido,
    } = this.state
    let productsCards,
      productsInvalid = null

    if (productos.length >= 0) {
      productsCards = productos.map((prod) => (
        <ProductFromExcelCard key={uuid()} producto={prod} />
      ))
    }
    if (productos_invalidos.length > 0) {
      productsInvalid = (
        <div className="card">
          <div className="card-content">
            <h5>Productos invalidos {productos_invalidos.length}</h5>
            {productos_invalidos.map((prod) => (
              <div className="card" key={uuid()}>
                <div className="card-content">
                  <span className="d-block">
                    Codigo: {!isEmpty(prod.codigo) ? prod.codigo : "Sin codigo"}
                  </span>
                  <span className="d-block">
                    Descripcion:{" "}
                    {!isEmpty(prod.descripcion)
                      ? prod.descripcion
                      : "Sin descripcion"}
                  </span>
                  <span className="d-block">
                    Precio: {prod.precio >= 0 ? prod.precio : "Sin precio"}
                  </span>

                  <span className="d-block">
                    Local: {!isEmpty(prod.local) ? prod.local : "Sin local"}
                  </span>

                  <span className="d-block">
                    Ubicacion:{" "}
                    {!isEmpty(prod.ubicacion)
                      ? prod.ubicacion
                      : "Sin ubicacion"}
                  </span>

                  <span className="d-block">
                    Cantidad:{" "}
                    {prod.cantidad >= 0 ? prod.cantidad : "Sin cantidad"}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
    return (
      <React.Fragment>
        <NavbarAdmin>
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Importar productos
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSaveProducts}>
                  <i className="material-icons">save</i>
                </a>
              </li>
            </ul>
          </div>
        </NavbarAdmin>

        <main>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content">
                  <h5>Productos totales: {productos.length}</h5>
                  <div className="row">
                    <InputFile
                      input_size="s12 m7 l7"
                      id="excel_file"
                      label="Seleccione un archivo"
                      onchange={this.onChangeExcelFile}
                    />
                  </div>

                  <div className="row">
                    <CheckInputField
                      id="buscar_en_server"
                      label="Auto completar datos de productos con el servidor"
                      checked={buscar_en_server}
                      onchange={this.onChangeCheckField}
                    />
                  </div>
                  <div className="row">
                    <CheckInputField
                      id="marcar_cero_como_invalido"
                      label="Buscar el precio de productos con Lps 0"
                      checked={marcar_cero_como_invalido}
                      onchange={this.onChangeCheckBox}
                    />
                  </div>

                  <div className="row">
                    <TextInputField
                      id="cantidad_minima"
                      label="Cantidad minima"
                      input_size={"s12 m4 l4"}
                      onchange={this.onChangeTextInput}
                      value={cantidad_minima}
                      type="number"
                    />
                    <TextInputField
                      id="cantidad_minima_local"
                      label="Cantidad minima para bodegas"
                      input_size={"s12 m4 l4"}
                      onchange={this.onChangeTextInput}
                      value={cantidad_minima_local}
                      type="number"
                    />
                    <div className="col s12 m4 l4">
                      <button
                        className="btn"
                        onClick={this.onClickApplyChanges}
                      >
                        Aplicar
                      </button>
                    </div>
                  </div>

                  {(loading_json || this.props.product.loading) && (
                    <Spinner fullWidth />
                  )}
                  {productsInvalid}
                  {productsCards}
                </div>
              </div>
            </div>
          </div>
        </main>

        <ConfirmationModal
          title="Aviso"
          message={this.state.message}
          onAccept={this.onAcceptClick}
        />
      </React.Fragment>
    )
  }
}

NewProductsFromExcel.propTypes = {
  errors: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  addMultiple: PropTypes.func.isRequired,
  searchProductsToImport: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  product: state.product,
  errors: state.errors,
})

export default connect(mapStateToProps, {
  addMultiple,
  searchProductsToImport,
})(NewProductsFromExcel)
