import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { configSelectInputFields } from "../../utils/MaterialFunctions";
import { getLocals, localsToSelectOptions } from "../../actions/LocalActions";
import {
  getClients,
  clientsToSelectOptions,
} from "../../actions/clientActions";
import { getSellReport } from "../../actions/sellActions";
import InputField from "./TextInputField";
import SelectInputField from "./SelectInputField";

import { getCurrentDateToInput } from "../../utils/dateFormat";
import isEmpty from "../../actions/isEmpty";
import { arraysToExcel } from "../../utils/jsonExcel";

class SellReportOptions extends Component {
  state = {
    fecha_inicio: "",
    fecha_final: "",
    id_cliente: "0",
    id_local: "0",
    tipo_reporte: "0",
    config_selects: false,
    tipos_reporte: [
      {
        value: "1",
        label: "Valores totales",
      },
      {
        value: "2",
        label: "Ventas detalladas",
      },
    ],
    op_locales: [],
    op_clientes: [],
  };

  componentDidMount() {
    const fecha = getCurrentDateToInput();
    this.setState({
      fecha_final: fecha,
      fecha_inicio: fecha,
    });
    if (this.props.is_admin) {
      this.props.getLocals();
    }
    this.props.getClients();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.is_admin && nextProps.local.locals) {
      this.setState({
        op_locales: localsToSelectOptions(nextProps.local.locals),
        config_selects: true,
      });
    }

    if (nextProps.client.clients) {
      this.setState({
        op_clientes: clientsToSelectOptions(nextProps.client.clients),
        config_selects: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.config_selects) {
      configSelectInputFields();
      this.setState({
        config_selects: false,
      });
    }
  }

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  onGetReportClick = () => {
    let { fecha_inicio, fecha_final, id_cliente, id_local, tipo_reporte } =
      this.state;
    if (tipo_reporte === "0") {
      return document.getElementById("tipo_reporte").focus();
    } else {
      if (tipo_reporte === "1") {
        tipo_reporte = "ventas_totales";
      } else if (tipo_reporte === "2") {
        tipo_reporte = "ventas_detalle";
      }
    }

    if (id_cliente === "0") {
      id_cliente = null;
    }
    if (id_local === "0") {
      id_local = null;
    }
    const reportJson = {
      fecha_inicio,
      fecha_final,
      id_cliente,
      id_local,
      reporte_tipo: tipo_reporte,
    };
    this.props.getSellReport(reportJson);
  };

  onPrintExcelReport = () => {
    const { report, loading } = this.props.sell;

    if (loading) {
      return;
    }

    if (isEmpty(report)) return;

    if (report.type === "ventas_totales") {
      this.printExcelVentasTotales(report);
    } else if (report.type === "ventas_detalle") {
      this.printExcelVentasDetalladas(report);
    }
  };

  printExcelVentasTotales = (report) => {
    const reportData = [];
    reportData.push([
      "Fecha inicio",
      report.fecha_inicio,
      "",
      "Fecha final",
      report.fecha_final,
      "",
      "Tipo",
      "Ventas totales",
    ]);
    reportData.push([]);
    reportData.push(["ID Venta", "Local", "Cliente", "Total", "Fecha"]);
    let totalVentas = 0;
    report.data.forEach((venta) => {
      const totalVenta = parseFloat(venta.total);
      reportData.push([
        venta.id,
        venta.local,
        venta.cliente,
        totalVenta,
        venta.fecha_creado,
      ]);
      totalVentas += totalVenta;
    });
    reportData.push(["", "", "", totalVentas, ""]);
    arraysToExcel("Reporte de ventas totales", reportData);
  };

  printExcelVentasDetalladas = (report) => {
    const reportData = [];
    reportData.push([
      "Fecha inicio",
      report.fecha_inicio,
      "",
      "Fecha final",
      report.fecha_final,
      "",
      "Tipo",
      "Ventas detalladas",
    ]);
    reportData.push([]);
    reportData.push([
      "ID Venta",
      "Cliente",
      "RTN",
      "Vendedor",
      "Codigo de barra",
      "Producto",
      "Precio",
      "Cantidad",
      "Sub Total",
      "Total",
      "Comision",
      "Fecha",
    ]);

    const vendedoresTotales = {};
    const ventaTotales = {
      sub_total: 0,
      total: 0,
      comision: 0,
    };

    report.data.forEach((venta) => {
      const cliente = venta.cliente || {
        nombre: "",
        rtn: "",
      };
      const vendedor = venta.usuario_creador || {
        id: "",
        nombre: "",
      };
      const ventaImpuesto = parseFloat(venta.impuesto);
      const ventaTieneImpuesto = !isNaN(ventaImpuesto) && ventaImpuesto > 0;
      if (venta.usuario_creador) {
        if (!vendedoresTotales[venta.usuario_creador.id]) {
          vendedoresTotales[venta.usuario_creador.id] = {
            id: vendedor.id,
            nombre: vendedor.nombre,
            venta_subtotal: 0,
            venta_total: 0,
            comision: 0,
          };
        }
        vendedoresTotales[venta.usuario_creador.id].venta_subtotal +=
          parseFloat(venta.sub_total);
        vendedoresTotales[venta.usuario_creador.id].venta_total += parseFloat(
          venta.total
        );
        vendedoresTotales[venta.usuario_creador.id].comision += parseFloat(
          venta.comision
        );
      }
      ventaTotales.sub_total += parseFloat(venta.sub_total);
      ventaTotales.total += parseFloat(venta.total);
      ventaTotales.comision += parseFloat(venta.comision);

      venta.productos.forEach((producto) => {
        const productoPrecio = parseFloat(producto.precio);
        const productoCantidad = parseFloat(producto.cantidad);
        const productoSubTotal = productoCantidad * productoPrecio;
        const productoTotal = ventaTieneImpuesto
          ? productoSubTotal * 1.15
          : productoSubTotal;
        reportData.push([
          parseFloat(venta.id),
          cliente.nombre,
          cliente.rtn,
          vendedor.nombre,
          producto.codigo_barra,
          producto.nombre,
          productoPrecio,
          productoCantidad,
          productoSubTotal,
          productoTotal,
          this.getProductComission(producto),
          venta.fecha_creado,
        ]);
      });
    });
    reportData.push([
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ventaTotales.sub_total,
      ventaTotales.total,
      ventaTotales.comision,
      "",
    ]);

    reportData.push([]);
    reportData.push([
      "ID Vendedor",
      "Vendedor",
      "Venta sub total",
      "Venta Total",
      "Comision",
    ]);
    const vendedoresCifras = {
      sub_total: 0,
      total: 0,
      comision: 0,
    };

    Object.keys(vendedoresTotales).forEach((key) => {
      const vendedorActual = vendedoresTotales[key];
      reportData.push([
        vendedorActual.id,
        vendedorActual.nombre,
        vendedorActual.venta_subtotal,
        vendedorActual.venta_total,
        vendedorActual.comision,
      ]);
      vendedoresCifras.sub_total += vendedorActual.venta_subtotal;
      vendedoresCifras.total += vendedorActual.venta_total;
      vendedoresCifras.comision += vendedorActual.comision;
    });
    reportData.push([
      "",
      "",
      vendedoresCifras.sub_total,
      vendedoresCifras.total,
      vendedoresCifras.comision,
    ]);

    arraysToExcel("Reporte de ventas detalladas", reportData);
  };

  getProductComission = (producto) => {
    let total = parseFloat(producto.total);
    if (isNaN(total) || total < 0) {
      total = 0;
    }
    return total * 0.05;
  };

  render() {
    const {
      fecha_final,
      fecha_inicio,
      id_local,
      id_cliente,
      tipo_reporte,
      tipos_reporte,
      op_clientes,
      op_locales,
    } = this.state;
    return (
      <div className="card">
        <div className="card-content">
          <div className="row">
            <InputField
              id="fecha_inicio"
              input_size="s12 m6"
              label="Fecha de inicio"
              type="date"
              value={fecha_inicio}
              onchange={this.onChangeTextInput}
              active_label={true}
            />

            <InputField
              id="fecha_final"
              input_size="s12 m6"
              label="Fecha fin"
              type="date"
              value={fecha_final}
              onchange={this.onChangeTextInput}
              active_label={true}
            />
          </div>

          <div className="row">
            {this.props.is_admin && (
              <SelectInputField
                input_size="s12 m6"
                id="id_local"
                label="Local"
                options={op_locales}
                value={id_local}
                onchange={this.onChangeTextInput}
              />
            )}

            <SelectInputField
              input_size="s12 m6"
              id="id_cliente"
              label="Cliente"
              options={op_clientes}
              value={id_cliente}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <SelectInputField
              input_size="s12 m6"
              id="tipo_reporte"
              label="Tipo de reporte"
              options={tipos_reporte}
              value={tipo_reporte}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <div className="col s12">
              <button className="btn" onClick={this.onGetReportClick}>
                Generar reporte
              </button>
              <button
                className="btn"
                onClick={this.onPrintExcelReport}
                style={{
                  marginLeft: "10px",
                }}
              >
                Exportar como Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SellReportOptions.propTypes = {
  local: PropTypes.object.isRequired,
  is_admin: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  sell: PropTypes.object.isRequired,
  getLocals: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getSellReport: PropTypes.func.isRequired,
};

SellReportOptions.defaultProps = {
  is_admin: false,
};

const mapStateToProps = (state) => ({
  local: state.local,
  client: state.client,
  sell: state.sell,
});

export default connect(mapStateToProps, {
  getLocals,
  getClients,
  getSellReport,
})(SellReportOptions);
