import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";

const APP_MAX_PAGES_PAGINATION = 15;

function getPaginationLink(pageNumber, onClick, isActive = false) {
  return (
    <li className={`waves-effect ${isActive ? "active" : ""} `} key={uuid()}>
      <a
        href="#!"
        onClick={() => {
          onClick(pageNumber);
        }}
      >
        {pageNumber}
      </a>
    </li>
  );
}

function getAllLinks(numberOfLinks, onClickFN, currentPage) {
  const response = [];
  for (let index = 0; index < numberOfLinks; index++) {
    const pageNumber = index + 1;
    response.push(
      getPaginationLink(pageNumber, onClickFN, pageNumber === currentPage)
    );
  }
  return response;
}

function getCalculatedLinks(pagination, onClickFN) {
  const response = [];
  const pageNumbersToShow = getPageNumbers(pagination);

  // Agregar pagina 1
  response.push(getPaginationLink(1, onClickFN, pagination.currentPage === 1));

  for (const pageNumber of pageNumbersToShow) {
    response.push(
      getPaginationLink(
        pageNumber,
        onClickFN,
        pagination.currentPage === pageNumber
      )
    );
  }

  // Agregar pagina final
  response.push(
    getPaginationLink(
      pagination.pages,
      onClickFN,
      pagination.currentPage === pagination.pages
    )
  );

  return response;
}

/**
 * Retorna los numeros de las paginas a mostrar en la barra
 */
function getPageNumbers(pagination) {
  let response = [];

  // Pagina 1 y MAX siempre se muestran
  const maxNumberOfPages = APP_MAX_PAGES_PAGINATION - 2;

  // Agregar todos los numeros desde el 2 en adelante
  if (pagination.currentPage === 1) {
    for (let index = 0; index < maxNumberOfPages; index++) {
      response.push(index + 2);
    }
    return response;
  }

  // Agregar todos los numeros desde el maximo hacia atras
  if (pagination.currentPage === pagination.pages) {
    for (let index = 0; index < maxNumberOfPages; index++) {
      response.push(pagination.pages - (index + 1));
    }
    // Ordernar ascendente
    return response.sort((a, b) => a - b);
  }

  const startPage = Math.max(
    2,
    pagination.currentPage - Math.floor(maxNumberOfPages / 2)
  );
  const endPage = Math.min(
    pagination.pages - 1,
    startPage + maxNumberOfPages - 1
  );
  for (let index = startPage; index <= endPage; index++) {
    response.push(index);
  }

  response = response.sort((a, b) => a - b);
  if (response.length === maxNumberOfPages) return response;

  const pagesToAdd = maxNumberOfPages - response.length;
  /**
   * Diferencia de numeros entre la pagina 1 y la maxima
   * ejemplo: pagina maxima 20, minima 1, pagina actual 12
   */
  const differences = {
    max: pagination.pages - pagination.currentPage, // 8 paginas de diferencia con el maximo
    min: pagination.currentPage - 1, // 11 paginas de diferencia con el minimo
  };
  let lastPage = response[response.length - 1];
  let minPage = response[0];

  // Mostrar paginas mas cercanas al maximo
  if (
    differences.max < differences.min &&
    lastPage + pagesToAdd < pagination.pages
  ) {
    for (let index = 0; index < pagesToAdd; index++) {
      response.push(lastPage + 1);
      lastPage++;
    }
  }
  // Mostrar paginas mas cercanas al inicio
  else {
    for (let index = 0; index < pagesToAdd; index++) {
      response.push(minPage - 1);
      minPage--;
    }
  }

  return response.sort((a, b) => a - b);
}

function getPaginationFromProps(props) {
  let pagination = {
    currentPage: 1,
    from: 0,
    count: 15,
    total: 0,
    pages: 1,
  };

  if (props.pagination && props.pagination.currentPage) {
    pagination = props.pagination;
  }

  return pagination;
}

function PaginationBar(props) {
  const pagination = getPaginationFromProps(props);
  const onPageClickFN = (pageNumber) => {
    if (pageNumber !== pagination.currentPage) {
      props.onPageClick(pageNumber);
    }
  };
  const onPreviusPageClick = () => {
    const newPageNumber = pagination.currentPage - 1;
    if (newPageNumber <= 0) return;

    onPageClickFN(newPageNumber);
  };
  const onNextPageClick = () => {
    const newPageNumber = pagination.currentPage + 1;
    if (newPageNumber > pagination.pages) return;

    onPageClickFN(newPageNumber);
  };

  let linkElements = [];
  if (pagination.pages <= APP_MAX_PAGES_PAGINATION) {
    linkElements = getAllLinks(
      pagination.pages,
      onPageClickFN,
      pagination.currentPage
    );
  } else {
    linkElements = getCalculatedLinks(pagination, onPageClickFN);
  }

  return (
    <ul className="pagination">
      <li>
        <a href="#!" onClick={onPreviusPageClick}>
          <i className="material-icons">chevron_left</i>
        </a>
      </li>
      {linkElements}
      <li className="waves-effect">
        <a href="#!" onClick={onNextPageClick}>
          <i className="material-icons">chevron_right</i>
        </a>
      </li>
    </ul>
  );
}

PaginationBar.propTypes = {
  pagination: PropTypes.object.isRequired,
  onPageClick: PropTypes.func.isRequired,
};

export default PaginationBar;
