import React, { Component } from "react";
import NewNavbar from "../../layout/NewNavbar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import uuid from "uuid";

import {
  removeMaterialComponents,
  configMaterialComponents,
  configSelectInputFields,
  notificationError
} from "../../../utils/MaterialFunctions";

import {
  getLocals,
  localsToSelectOptions
} from "../../../actions/LocalActions";

import { createOrder } from "../../../actions/orderActions";

import { isEnterKey } from "../../../utils/keyActions";

import SearchProductModal from "../../layout/modals/SearchProductAndShowInfo";
import TextInputField from "../../common/TextInputField";
import SelectInpuField from "../../common/SelectInputField";

class NewOrder extends Component {
  state = {
    id_local_solicitado: "0",
    fecha_entrega: "",
    codigo: "",
    productos: [],
    errors: {},
    need_config_selects: false
  };

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getLocals();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.need_config_selects &&
      nextProps.local &&
      nextProps.local.locals.length > 0
    ) {
      this.setState({
        need_config_selects: true
      });
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidUpdate() {
    if (this.state.need_config_selects) {
      configSelectInputFields();
      this.setState({
        need_config_selects: false
      });
    }
  }

  onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

  onChangeProductCantidad = (producto, e) => {
    if (isEnterKey(e)) {
      const inputField = document.getElementById(
        `input_cantidad${producto.id}`
      );
      if (inputField) {
        const { productos } = this.state;
        const newValue = parseInt(inputField.value);
        const productIndex = productos.findIndex(
          prod => prod.id === producto.id
        );
        if (
          productIndex >= 0 &&
          newValue !== productos[productIndex].cantidad
        ) {
          productos[productIndex].cantidad = newValue;
          this.setState({ productos });
        }
      }
    }
  };

  onSelectProduct = producto => {
    const { productos } = this.state;
    const productIndex = productos.findIndex(prod => prod.id === producto.id);
    if (productIndex >= 0) {
      notificationError(
        "El producto ya se encuentra agregado en el pedido actual"
      );
      return;
    }

    producto.cantidad = 0;
    productos.splice(0, 0, producto);
    this.setState({ productos });
  };

  onDeleteProductClick = producto => {
    const { productos } = this.state;
    this.setState({ productos: productos.filter(prod => prod !== producto) });
  };

  getProductsSumCantidad = () => {
    let total = 0;
    this.state.productos.forEach(prod => {
      total += prod.cantidad;
    });
    return total;
  };

  getProductsContent = () => {
    const { productos } = this.state;
    let productsContent;
    if (productos.length > 0) {
      productsContent = (
        <React.Fragment>
          <table className="table-bordered">
            <thead>
              <tr>
                <th />
                <th>Codigo</th>
                <th>Descripcion</th>
                <th style={{ width: "130px" }}>Cantidad</th>
              </tr>
            </thead>

            <tbody>
              {productos.map(prod => (
                <tr key={uuid()}>
                  <td>
                    <i
                      className="material-icons cursor-pointer"
                      style={{ fontSize: "25px", color: "red" }}
                      onClick={this.onDeleteProductClick.bind(this, prod)}
                    >
                      delete
                    </i>
                  </td>
                  <td>{prod.codigo_barra}</td>
                  <td>{prod.nombre}</td>
                  <td>
                    <input
                      className="browser-default input-row-transparent"
                      id={`input_cantidad${prod.id}`}
                      defaultValue={prod.cantidad}
                      onKeyUp={this.onChangeProductCantidad.bind(this, prod)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <span className="d-block mt-1 bold">
            Cantidad de productos totales: {this.getProductsSumCantidad()}
          </span>
        </React.Fragment>
      );
    }
    return productsContent;
  };

  onSaveOrderClick = () => {
    const {
      id_local_solicitado,
      fecha_entrega,
      codigo,
      productos
    } = this.state;
    if (
      id_local_solicitado === "0" ||
      fecha_entrega === "" ||
      productos.length === 0
    ) {
      return;
    }

    const productsFormatted = [];
    productos.forEach(prod => {
      productsFormatted.push({
        id_producto: prod.id,
        cantidad: prod.cantidad,
        costo: 0
      });
    });
    const newOrderData = {
      id_local_solicitado,
      fecha_entrega,
      codigo,
      productos: productsFormatted
    };
    this.props.createOrder(newOrderData, this.props.history);
  };

  render() {
    const {
      id_local_solicitado,
      fecha_entrega,
      codigo,
      errors: { codigo_error, fecha_entrega_error, local_solicitado_error }
    } = this.state;
    const {
      local: { locals },
      user: { currentLocal }
    } = this.props;
    const localOptions = localsToSelectOptions(locals, [currentLocal]);
    return (
      <React.Fragment>
        <NewNavbar active_nav="PEDIDOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Nuevo pedido a tienda
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>

            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSaveOrderClick}>
                  <i className="material-icons">save</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content">
                  <h5>Detalles del pedido</h5>

                  <div className="row">
                    <TextInputField
                      id="codigo"
                      label="Codigo (opcional)"
                      value={codigo}
                      error={codigo_error}
                      onchange={this.onChangeTextInput}
                    />
                  </div>
                  <div className="row">
                    <SelectInpuField
                      input_size="s12 m6"
                      id="id_local_solicitado"
                      label="Local seleccionado"
                      value={id_local_solicitado}
                      onchange={this.onChangeTextInput}
                      options={localOptions}
                      error={local_solicitado_error}
                    />
                    <TextInputField
                      input_size="s12 m6"
                      id="fecha_entrega"
                      label="Fecha prevista de entrega"
                      type="date"
                      value={fecha_entrega}
                      error={fecha_entrega_error}
                      onchange={this.onChangeTextInput}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-content">
                  <h5>
                    Productos {this.state.productos.length}
                    <button
                      className="btn modal-trigger right circle-element "
                      data-target="search_product_and_show_info"
                      style={{ height: "35px", width: "35px", padding: "0px" }}
                    >
                      <i className="material-icons">add</i>
                    </button>
                  </h5>
                  {this.getProductsContent()}
                </div>
              </div>
            </div>
          </div>
        </main>

        <SearchProductModal onSelectProduct={this.onSelectProduct} />
      </React.Fragment>
    );
  }
}

NewOrder.propTypes = {
  local: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getLocals: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  local: state.local,
  user: state.user,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getLocals, createOrder }
)(NewOrder);
