import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { connect } from "react-redux";

import Spinner from "../../common/Spinner";
import EmptyIcon from "../../common/EmptyIcon";

import { getProductClientsPrices } from "../../../actions/productActions";

class ShowProductClientSells extends Component {
  state = {
    loading: false,
    clients: []
  };

  onGetSalesData = () => {
    this.setState({ loading: true });
    this.props.getProductClientsPrices(this.props.product_id, (_, data) => {
      this.setState({ loading: false, clients: data });
    });
  };

  getContent = () => {
    const { loading, clients } = this.state;
    if (loading) {
      return <Spinner fullWidth />;
    }
    if (clients.length === 0) {
      return <EmptyIcon message="No hay informacion disponible" />;
    }

    return (
      <table className="table-bordered striped">
        <thead>
          <tr>
            <td>Cliente</td>
            <td>Precio</td>
            <td>Cantidad</td>
            <td>Fecha</td>
          </tr>
        </thead>

        <tbody>
          {clients.map(client => (
            <tr key={uuid()}>
              <td>{client.nombre}</td>
              <td>{client.precio}</td>
              <td>{client.cantidad}</td>
              <td>{client.fecha}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  render() {
    return (
      <div className="modal" id={this.props.id}>
        <div className="modal-content">
          <h5>Precios a clientes</h5>
          {this.getContent()}
        </div>

        <div className="modal-footer">
          <a className="btn-flat modal-close left" href="#!">
            Cerrar
          </a>

          <a className="btn right" href="#!" onClick={this.onGetSalesData}>
            Obtener precios
          </a>
        </div>
      </div>
    );
  }
}

ShowProductClientSells.propTypes = {
  id: PropTypes.string.isRequired,
  getProductClientsPrices: PropTypes.func.isRequired,
  product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ShowProductClientSells.defaultProps = {
  id: "modal_product_cliente_ventas"
};

export default connect(
  null,
  { getProductClientsPrices }
)(ShowProductClientSells);
