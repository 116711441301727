import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NewNavbar from "../../layout/NewNavbar";

import {
  configMaterialComponents,
  removeMaterialComponents
} from "../../../utils/MaterialFunctions";

import SearchProviderModal from "../../layout/modals/SearchProviderModal";
import ProviderCard from "../../common/ProviderCard";
import { getProviders } from "../../../actions/providerActions";
import Spinner from "../../common/Spinner";
import EmptyIcon from "../../common/EmptyIcon";

class Providers extends Component {
  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getProviders();
  }

  onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { providers, loading } = this.props.providers;
    let providersContent;

    if (loading) {
      providersContent = <Spinner fullWidth />;
    } else if (providers.length > 0) {
      providersContent = providers.map(provider => (
        <div className="col s12 m6 l6" key={provider.id}>
          <ProviderCard provider={provider} />
        </div>
      ));
    } else {
      providersContent = <EmptyIcon message="No hay proveedores" />;
    }

    return (
      <React.Fragment>
        <NewNavbar active_nav="PROVEEDOR">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Proveedores
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a
                  href="#modal_buscar_proveedor"
                  className="tooltipped modal-trigger"
                  data-position="left"
                  data-tooltip="Buscar"
                >
                  <i className="material-icons cursor-pointer">search</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>
        <main>
          <div className="row">{providersContent}</div>
        </main>

        <SearchProviderModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  providers: state.provider
});

export default connect(
  mapStateToProps,
  { getProviders }
)(withRouter(Providers));
