export const removeUserInBottomPage = () => {
  if (isMobileDevice()) {
    window.ontouchmove = () => {};
  } else {
    window.onscroll = () => {};
  }
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const onUserInBottomPage = function_to_run => {
  const onUserInBottomEvent = () => {
    if (
      window.innerHeight + window.scrollY + 50 >=
      document.body.offsetHeight
    ) {
      function_to_run();
    }
  };
  if (isMobileDevice()) {
    window.ontouchmove = onUserInBottomEvent;
  } else {
    window.onscroll = onUserInBottomEvent;
  }
};

export const autosizeTextAreaHeight = (id_element, minHeight) => {
  const textArea = document.getElementById(id_element);
  if (textArea) {
    textArea.style.height = 0;
    let offset = textArea.offsetHeight - textArea.clientHeight;
    const new_height = Math.max(minHeight, textArea.scrollHeight + offset);
    textArea.style.height = `${new_height}px`;
  }
};
