import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Moment from "react-moment";

const OrderCard = props => {
  const {
    order: {
      id,
      codigo,
      fecha_creado,
      local,
      local_solicitado,
      proveedor,
      id_proveedor,
      recibido,
      fecha_recibido
    },
    currentLocal,
    is_admin
  } = props;
  return (
    <div className="card hoverable">
      <Link to={`${is_admin ? "/admin" : ""}/pedidos/${id}`}>
        <div className="card-content">
          <span className="d-block">
            Pedido #{id} {codigo}
          </span>
          <span className="d-block">
            Fecha de creacion:{" "}
            <Moment format="YYYY/MM/DD" date={fecha_creado} />{" "}
          </span>
          {recibido && (
            <span className="d-block">
              Recibido en {fecha_recibido}
              <div
                className="circle-element green lighten-1 right bordered"
                style={{ width: "20px", height: "20px" }}
              />
            </span>
          )}
          {local && local.id !== currentLocal.id && (
            <span className="d-block">Local: {local.nombre}</span>
          )}
          {id_proveedor ? (
            <span className="d-block">Proveedor: {proveedor.nombre}</span>
          ) : (
            <span className="d-block">
              Local solicitado: {local_solicitado.nombre}
            </span>
          )}
          {currentLocal &&
            local_solicitado &&
            currentLocal.id === local_solicitado.id && (
              <div className="w-100" style={{ height: "20px" }}>
                <div
                  className="circle-element deep-orange right bordered"
                  style={{ width: "20px", height: "20px" }}
                />
              </div>
            )}
        </div>
      </Link>
    </div>
  );
};

OrderCard.propTypes = {
  is_admin: PropTypes.bool.isRequired
};

OrderCard.defaultProps = {
  is_admin: false
};

export default OrderCard;
