import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import NewNavbar from '../../../layout/NewNavbarAdmin';
import {
  removeMaterialComponents,
  configMaterialComponents,
  configSelectInputFields,
  notificationError
} from '../../../../utils/MaterialFunctions';

import {
  getOrder,
  editOrder,
  clearOrder
} from '../../../../actions/orderActions';

import {
  getLocals,
  localsToSelectOptions
} from '../../../../actions/LocalActions';

import { isEnterKey } from '../../../../utils/keyActions';
import isEmpty from '../../../../actions/isEmpty';

import Spinner from '../../../common/Spinner';
import EmptyIcon from '../../../common/EmptyIcon';
import TextInputField from '../../../common/TextInputField';
import SelectInputField from '../../../common/SelectInputField';

import SearchProductModal from '../../../layout/modals/SearchProductAndShowInfo';

class EditLocalOrder extends Component {
  state = {
    id_local_solicitado: '0',
    fecha_entrega: '',
    codigo: '',
    productos: [],
    errors: {},
    need_config_selects: false,
    loaded: false
  };

  componentDidMount() {
    configMaterialComponents();
    this.props.getOrder(this.props.match.params.id);
    this.props.getLocals();
  }

  componentWillUnmount() {
    removeMaterialComponents();
    this.props.clearOrder();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.loaded &&
      nextProps.order.order &&
      !isEmpty(nextProps.order.order)
    ) {
      const {
        id_local_solicitado,
        codigo,
        fecha_prevista_entrega,
        productos
      } = nextProps.order.order;
      this.setState({
        id_local_solicitado,
        codigo: codigo ? codigo : '',
        productos,
        fecha_entrega: fecha_prevista_entrega,
        loaded: true
      });
    }

    if (nextProps.local.locals && nextProps.local.locals.length > 0) {
      this.setState({
        need_config_selects: true
      });
    }
  }

  componentDidUpdate() {
    if (this.state.need_config_selects) {
      configSelectInputFields();
      this.setState({
        need_config_selects: false
      });
    }
  }

  onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

  onSelectProduct = producto => {
    const { productos } = this.state;
    const productIndex = productos.findIndex(
      prod => prod.id_producto === producto.id && !prod.eliminado
    );
    if (productIndex >= 0) {
      notificationError(
        'El producto ya se encuentra agregado en el pedido actual'
      );
      return;
    }
    producto.id_producto = producto.id;
    delete producto.id;
    producto.cantidad = 0;
    producto.costo = 0;
    producto.nuevo = true;
    productos.splice(0, 0, producto);
    this.setState({ productos });
  };

  onChangeProductCantidad = (producto, e) => {
    if (isEnterKey(e)) {
      const id_producto = producto.nuevo ? producto.id_producto : producto.id;
      const inputField = document.getElementById(
        `input_cantidad${id_producto}`
      );
      if (inputField) {
        const { productos } = this.state;
        const newValue = parseInt(inputField.value);
        let productIndex = -1;
        if (producto.nuevo) {
          productIndex = productos.findIndex(
            prod => prod.id_producto === id_producto
          );
        } else {
          productIndex = productos.findIndex(prod => prod.id === id_producto);
        }
        if (
          productIndex >= 0 &&
          newValue !== productos[productIndex].cantidad
        ) {
          productos[productIndex].cantidad = newValue;
          if (!producto.nuevo) {
            productos[productIndex].actualizado = true;
          }
          this.setState({ productos });
        }
      }
    }
  };

  onDeleteProductClick = producto => {
    const { productos } = this.state;
    if (!producto.nuevo) {
      const productIndex = productos.findIndex(prod => prod.id === producto.id);
      if (productIndex >= 0) {
        productos[productIndex].eliminado = true;
        delete productos[productIndex].actualizado;
        this.setState({ productos });
      }
    } else {
      this.setState({ productos: productos.filter(prod => prod !== producto) });
    }
  };

  getProductsSumCantidad = () => {
    let total = 0;
    this.state.productos
      .filter(prod => !prod.eliminado)
      .forEach(prod => {
        total += parseInt(prod.cantidad);
      });
    return total;
  };

  onSaveClick = () => {
    const {
      productos,
      fecha_entrega,
      codigo,
      id_local_solicitado
    } = this.state;
    const newOrderData = {
      id_local_solicitado,
      codigo,
      fecha_prevista_entrega: fecha_entrega,
      productos
    };
    this.props.editOrder(this.props.match.params.id, newOrderData, () => {
      window.location.reload();
    });
  };

  getPageContent = () => {
    const { loading, order } = this.props.order;
    if (loading) {
      return <Spinner fullWidth />;
    } else if (!isEmpty(order)) {
      return (
        <div className="col s12">
          {this.getHeaderOrder()}
          {this.getProductsOrderContent()}
        </div>
      );
    } else {
      return <EmptyIcon message="No hay informacion para este pedido" />;
    }
  };

  getHeaderOrder = () => {
    const {
      codigo,
      fecha_entrega,
      id_local_solicitado,
      errors: { codigo_error, local_solicitado_error, fecha_entrega_error }
    } = this.state;
    const {
      local: { locals },
      user: { currentLocal }
    } = this.props;
    const localOptions = localsToSelectOptions(locals, [currentLocal]);
    return (
      <div className="card">
        <div className="card-content">
          <h5>Detalles del pedido</h5>

          <div className="row">
            <TextInputField
              id="codigo"
              label="Codigo (opcional)"
              value={codigo}
              error={codigo_error}
              active_label={true}
              onchange={this.onChangeTextInput}
            />
          </div>
          <div className="row">
            <SelectInputField
              input_size="s12 m6"
              id="id_local_solicitado"
              label="Local seleccionado"
              value={id_local_solicitado}
              onchange={this.onChangeTextInput}
              options={localOptions}
              error={local_solicitado_error}
            />
            <TextInputField
              input_size="s12 m6"
              id="fecha_entrega"
              label="Fecha prevista de entrega"
              type="date"
              value={fecha_entrega}
              error={fecha_entrega_error}
              onchange={this.onChangeTextInput}
            />
          </div>
        </div>
      </div>
    );
  };

  getProductsOrderContent = () => {
    const productos = this.state.productos.filter(prod => !prod.eliminado)
    return (
      <div className="card">
        <div className="card-content">
          <h5>
            Productos: {productos.length}
            <button
              className="btn modal-trigger right circle-element "
              data-target="search_product_and_show_info"
              style={{ height: '35px', width: '35px', padding: '0px' }}
            >
              <i className="material-icons">add</i>
            </button>
          </h5>

          <table className="table-bordered mt-1">
            <thead>
              <tr>
                <th />
                <th>Codigo</th>
                <th>Descripcion</th>
                <th style={{ width: '130px' }}>Cantidad</th>
              </tr>
            </thead>

            <tbody>
              {productos
                .map(prod => (
                  <tr key={uuid()}>
                    <td>
                      <i
                        className="material-icons cursor-pointer"
                        style={{ fontSize: '25px', color: 'red' }}
                        onClick={this.onDeleteProductClick.bind(this, prod)}
                      >
                        delete
                      </i>
                    </td>
                    <td>
                      {prod.producto
                        ? prod.producto.codigo_barra
                        : prod.codigo_barra}
                    </td>
                    <td>{prod.nombre}</td>
                    <td>
                      <input
                        className="browser-default input-row-transparent"
                        id={`input_cantidad${
                          prod.nuevo ? prod.id_producto : prod.id
                        }`}
                        defaultValue={prod.cantidad}
                        onKeyUp={this.onChangeProductCantidad.bind(this, prod)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <span className="d-block mt-1 bold">
            Cantidad de productos totales: {this.getProductsSumCantidad()}
          </span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <NewNavbar active_nav="PEDIDOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Editar pedido: #{this.props.match.params.id}
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSaveClick}>
                  <i className="material-icons">save</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">{this.getPageContent()}</div>
        </main>

        <SearchProductModal onSelectProduct={this.onSelectProduct} />
      </React.Fragment>
    );
  }
}

EditLocalOrder.propTypes = {
  user: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  local: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getLocals: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  order: state.order,
  local: state.local,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getOrder, editOrder, getLocals, clearOrder }
)(EditLocalOrder);
