import React, { Component } from "react";
import { Link } from "react-router-dom";

import NavbarAdmin from "../../../layout/NewNavbarAdmin";

import {
  configMaterialComponents,
  removeMaterialComponents,
  initModal,
  getModalInstanceById,
} from "../../../../utils/MaterialFunctions";

import ShowProducts from "../../../common/ShowProducts";
import SearchProductsModal from "../../../layout/modals/SearchProductModel";

class AdminProducts extends Component {
  state = {
    searching: false,
  };

  modal_search = "modal_search_producto";

  componentDidMount() {
    configMaterialComponents({ modals: false });
    initModal(this.modal_search);
  }

  componentWillUnmount() {
    removeMaterialComponents();
  }

  onSearchModal = () => {
    getModalInstanceById(this.modal_search).open();
    this.setState({ searching: true });
  };

  onCloseSearchModal = () => {
    this.setState({ searching: false });
  };

  render() {
    const { searching } = this.state;
    return (
      <React.Fragment>
        <NavbarAdmin>
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Productos
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>
            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSearchModal}>
                  <i className="material-icons">search</i>
                </a>
              </li>
              <li>
                <Link to="/admin/nuevo_producto">
                  <i className="material-icons">add</i>
                </Link>
              </li>
            </ul>
          </div>
        </NavbarAdmin>

        <main>
          <ShowProducts admin={true} searching={searching} />
        </main>

        <SearchProductsModal
          is_admin={true}
          onClose={this.onCloseSearchModal}
        />
      </React.Fragment>
    );
  }
}

export default AdminProducts;
