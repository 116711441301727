/*
    sellActions
    Este archivo contiene todas las funciones para realizar peticiones HTPP
    al servidor referentes a VENTAS
*/

import axios from "axios";
import {
  SELL_LOADING,
  SELL_END_LOADING,
  GET_SELL,
  GET_SELLS,
  SELL_FAILED,
  SELL_SUCCESS,
  GET_SELL_REPORT,
  CLEAR_SELL_STATE,
  SELL_ORDER_PRODUCTS,
  SELL_UPDATE_PRODUCTS,
} from "../actions/types";

import { configUserFromResponse } from "./UserActions";
import { handleError, clearErrors } from "./errorActions";

import { API_URL } from "../config";

export const reorderSellProducts = () => (dispatch) => {
  dispatch({
    type: SELL_ORDER_PRODUCTS,
  });
};

export const updateSellProducts = (idSell, products) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .put(`${API_URL}/sales/update_sell_products/${idSell}`, {
      products,
    })
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: SELL_UPDATE_PRODUCTS,
        payload: response.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const getSells =
  (postData = {}) =>
  (dispatch) => {
    dispatchSellLoading(dispatch);
    axios
      .post(`${API_URL}/sales/get`, postData)
      .then((res) => {
        const response = res.data;
        configUserFromResponse(response, dispatch);
        dispatch({
          type: GET_SELLS,
          payload: response.data,
        });
        dispatch(clearErrors());
      })
      .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
  };

export const getQuotes =
  (postData = {}) =>
  (dispatch) => {
    dispatchSellLoading(dispatch);
    axios
      .post(`${API_URL}/sales/getquotes`, postData)
      .then((res) => {
        const response = res.data;
        configUserFromResponse(response, dispatch);
        dispatch({
          type: GET_SELLS,
          payload: response.data,
        });
        dispatch(clearErrors());
      })
      .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
  };

export const searchSell = (jsonField) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .post(`${API_URL}/sales/search`, jsonField)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch({
        type: GET_SELLS,
        payload: response.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const getSellById = (id) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .get(`${API_URL}/sales/get_one/${id}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: GET_SELL,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const addDaysToCredit = (id, days) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .post(`${API_URL}/sales/add_credit_days/${id}/${days}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: GET_SELL,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const markCreditFinished = (id) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .post(`${API_URL}/sales/mark_credit_complete/${id}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: GET_SELL,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const getQuotationById = (id) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .get(`${API_URL}/sales/get_quote/${id}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: GET_SELL,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const addNewSell = (newSellData) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .post(`${API_URL}/sales/add`, newSellData)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: SELL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_FAILED));
};

export const updateQuotation = (id, newSellData) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .put(`${API_URL}/sales/update_sell/${id}`, newSellData)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: SELL_SUCCESS,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_FAILED));
};

export const deleteQuotation = (id, history, new_url) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .delete(`${API_URL}/sales/delete_quote/${id}`)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({ type: SELL_END_LOADING });
      history.push(new_url);
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const addDevolution =
  (id_sell, devolutionData, history) => (dispatch) => {
    dispatchSellLoading(dispatch);
    axios
      .post(`${API_URL}/devolutions/add/${id_sell}`, devolutionData)
      .then((res) => {
        const response = res.data;
        configUserFromResponse(response, dispatch);
        dispatch(clearErrors());
        dispatch({ type: SELL_END_LOADING });
        history.push(`/devoluciones/${response.data.id}`);
      })
      .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
  };

export const getSellReport = (jsonData) => (dispatch) => {
  dispatchSellLoading(dispatch);
  axios
    .post(`${API_URL}/sales/get_report`, jsonData)
    .then((res) => {
      const response = res.data;
      configUserFromResponse(response, dispatch);
      dispatch(clearErrors());
      dispatch({
        type: GET_SELL_REPORT,
        payload: response.data,
      });
    })
    .catch((err) => handleError(err, dispatch, SELL_END_LOADING));
};

export const clearSellState = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELL_STATE,
  });
};

export const dispatchSellLoading = (dispatch) => {
  dispatch(sellLoadingObject());
};

export const dispatchSellEndLoading = (dispatch) => {
  dispatch({
    type: SELL_END_LOADING,
  });
};

export const sellLoadingObject = () => {
  return {
    type: SELL_LOADING,
  };
};
