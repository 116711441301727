import {
  KARDEX_LOADING,
  KARDEX_END_LOADING,
  KARDEX_GET_PRODUCTS,
  KARDEX_CLEAR_PRODUCTS_STATE,
} from "../actions/types";

const initialState = {
  loading: false,
  results: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case KARDEX_LOADING:
      return {
        ...state,
        loading: true,
      };

    case KARDEX_END_LOADING:
      return {
        ...state,
        loading: false,
      };

    case KARDEX_GET_PRODUCTS: {
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    }

    case KARDEX_CLEAR_PRODUCTS_STATE:
      return {
        ...state,
        loading: false,
        results: [],
      };

    default:
      return state;
  }
}
