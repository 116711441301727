import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
  configMaterialComponents,
  removeMaterialComponents
} from '../../../../utils/MaterialFunctions';

import {
  getOrder,
  deleteOrder,
  markReceived,
  clearOrder
} from '../../../../actions/orderActions';

import { jsonToExcel } from '../../../../utils/jsonExcel';

import isEmpty from '../../../../actions/isEmpty';

import NewNavbar from '../../../layout/NewNavbarAdmin';
import ConfirmationModal from '../../../layout/modals/ConfirmationModal';
import EmptyIcon from '../../../common/EmptyIcon';
import Spinner from '../../../common/Spinner';

class Order extends Component {
  componentWillUnmount() {
    removeMaterialComponents();
    this.props.clearOrder();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getOrder(this.props.match.params.id);
  }

  onConfirmDeleteOrder = () => {
    this.props.deleteOrder(
      this.props.match.params.id,
      this.props.history,
      '/admin/pedidos'
    );
  };

  onMarkReceived = () => {
    this.props.markReceived(this.props.match.params.id, this.props.history);
  };

  getLocalOrderProducts = order => {
    return (
      <div className="row">
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              <h5>Productos: {order.productos.length}</h5>
              <table className="table-bordered striped">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Codigo</th>
                    <th>Cantidad</th>
                  </tr>
                </thead>

                <tbody>
                  {order.productos.map(prod => (
                    <tr key={uuid()}>
                      <td>{prod.nombre}</td>
                      <td>{prod.producto.codigo_barra}</td>
                      <td>{prod.cantidad}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getOrderProductsProvider = order => {
    return (
      <div className="row">
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              <h5>Productos: {order.productos.length}</h5>
              {order.productos.map(producto =>
                this.getProviderProductCard(producto)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  getProviderProductCard = producto => {
    const {
      producto: { codigo_barra },
      nombre,
      cantidad
    } = producto;
    return (
      <div className="card" key={uuid()}>
        <div className="card-content">
          <h5>{nombre}</h5>
          <span className="d-block">Codigo: {codigo_barra}</span>
          <span className="d-block">Cantidad: {cantidad}</span>
          {this.getProductoDistribucionContent(producto)}
        </div>
      </div>
    );
  };

  getProductoDistribucionContent = producto => {
    const { distribucion } = producto;
    if (distribucion.length === 0) return null;
    return (
      <table>
        <thead>
          <tr>
            <th>Local</th>
            <th style={{ width: '40%' }}>Cantidad</th>
          </tr>
        </thead>

        <tbody>
          {distribucion.map(dist => (
            <tr key={uuid()}>
              <td>
                {dist.local.codigo} - {dist.local.nombre}
              </td>
              <td>{dist.cantidad}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  onPrintOrder = () => {
    const formattedProducts = [];
    const { productos } = this.props.orders.order;
    productos.forEach(prod => {
      const newProduct = {
        codigo: prod.producto.codigo_barra,
        cantidad: prod.cantidad
      };
      formattedProducts.push(newProduct);
    });
    if (formattedProducts.length === 0) return;
    jsonToExcel(
      formattedProducts,
      { codigo: 'Codigo', cantidad: 'Cantidad' },
      'rimeim_pedido'
    );
  };

  render() {
    const {
      orders: { order, loading },
      user: { currentLocal, user }
    } = this.props;

    let orderContent;
    let can_edit_order = false;

    if (loading) {
      orderContent = <Spinner fullWidth />;
    } else if (!isEmpty(order)) {
      if (user.admin) {
        can_edit_order = true;
      } else {
        can_edit_order =
          order.local && order.local.id && order.local.id === currentLocal.id;
      }

      orderContent = (
        <div className="row">
          <div className="col s12">
            <div className="card">
              <div className="card-content">
                <table>
                  <tbody>
                    {order.codigo && (
                      <tr>
                        <td>Codigo</td>
                        <td>{order.codigo}</td>
                      </tr>
                    )}
                    {order.local && order.local.id !== currentLocal.id && (
                      <tr>
                        <td>Local</td>
                        <td>
                          {order.local.codigo} - {order.local.nombre}
                        </td>
                      </tr>
                    )}
                    {order.proveedor && (
                      <tr>
                        <td>Proveedor</td>
                        <td>
                          {order.proveedor.rtn} - {order.proveedor.nombre}
                        </td>
                      </tr>
                    )}
                    {order.local_solicitado && (
                      <tr>
                        <td>Local solicitado</td>
                        <td>
                          {order.local_solicitado.codigo} -{' '}
                          {order.local_solicitado.nombre}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Realizado por</td>
                      <td>
                        {order.usuario_creador.id} -{' '}
                        {order.usuario_creador.nombre}
                      </td>
                    </tr>
                    <tr>
                      <td>Fecha prevista de entrega</td>
                      <td>{order.fecha_prevista_entrega}</td>
                    </tr>
                    <tr>
                      <td>Recibido</td>
                      <td>
                        {' '}
                        <div
                          className={`circle-element ${
                            order.recibido ? 'green lighten-1' : 'amber'
                          } bordered`}
                          style={{ width: '20px', height: '20px' }}
                        />
                      </td>
                    </tr>
                    {order.fecha_recibido && (
                      <tr>
                        <td>Recibido el</td>
                        <td>{order.fecha_recibido}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Fecha de creacion</td>
                      <td>{order.fecha_creado}</td>
                    </tr>
                  </tbody>
                </table>

                {can_edit_order && (
                  <div>
                    <button
                      className="btn red darken-3 modal-trigger"
                      data-target="modal_confirmar_evento"
                    >
                      Eliminar
                    </button>

                    {!order.recibido && (
                      <button
                        className="btn modal-trigger right"
                        data-target="modal_confirmar_evento_completado"
                      >
                        Marcar como completado
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            {order.local_solicitado
              ? this.getLocalOrderProducts(order)
              : this.getOrderProductsProvider(order)}
          </div>
        </div>
      );
    } else {
      orderContent = (
        <EmptyIcon message="No hay informacion para este pedido" />
      );
    }

    return (
      <React.Fragment>
        <NewNavbar active_nav="PEDIDOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Pedido: #{this.props.match.params.id}
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>

            {can_edit_order && (
              <ul className="right">
                <li>
                  <a href="#!" onClick={this.onPrintOrder}>
                    <i className="material-icons">print</i>
                  </a>
                </li>
                <li>
                  <Link
                    to={`/admin/editar_pedido/${
                      this.props.orders.order.local_solicitado
                        ? 'local'
                        : 'proveedor'
                    }/${this.props.match.params.id}`}
                    className="tooltipped"
                    data-position="left"
                    data-tooltip="Editar"
                  >
                    <i className="material-icons">edit</i>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </NewNavbar>

        <main>{orderContent}</main>

        <ConfirmationModal
          title="Eliminar pedido"
          message="Esta seguro de que quiere eliminar este pedido? No se podra revertir la operacion"
          onAccept={this.onConfirmDeleteOrder}
        />

        <ConfirmationModal
          title="Completar pedido"
          message="Esta seguro de que quiere marcar este pedido como completado? El inventario se incrementara segun la distribucion de los productos"
          onAccept={this.onMarkReceived}
          id="modal_confirmar_evento_completado"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.order,
  user: state.user
});

export default connect(
  mapStateToProps,
  { getOrder, deleteOrder, markReceived, clearOrder }
)(withRouter(Order));
