import React, { Component } from "react";
import NewNavbar from "../../layout/NewNavbar";
import { connect } from "react-redux";
import uuid from "uuid";

import {
  removeMaterialComponents,
  configMaterialComponents,
  configSelectInputFields,
  notificationError
} from "../../../utils/MaterialFunctions";

import { createOrder } from "../../../actions/orderActions";

import {
  getProviders,
  providersToSelectOptions
} from "../../../actions/providerActions";
import isEmpty from "../../../actions/isEmpty";
import { getLocals } from "../../../actions/LocalActions";
import { isEnterKey } from "../../../utils/keyActions";

import Spinner from "../../common/Spinner";
import TextInputField from "../../common/TextInputField";
import SelectInputField from "../../common/SelectInputField";
import CheckInputField from "../../common/CheckInputField";
import SearchProductModal from "../../layout/modals/SearchProductAndShowInfo";

class NewOrderProvider extends Component {
  state = {
    codigo: "",
    id_proveedor: "0",
    fecha_entrega: "",
    productos: [],
    errors: {},
    provider_already_config: false,
    need_config_selects: false,
    agregar_distribucion: true
  };

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.props.getProviders();
    if (this.canEditDistribution()) {
      this.props.getLocals();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.provider.providers &&
      nextProps.provider.providers.length > 0 &&
      !this.state.provider_already_config
    ) {
      this.setState({
        need_config_selects: true,
        provider_already_config: true
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  componentDidUpdate() {
    if (this.state.need_config_selects) {
      configSelectInputFields();
      this.setState({ need_config_selects: false });
    }
  }

  onChangeTextInput = e => this.setState({ [e.target.name]: e.target.value });

  onChangeCheckField = e =>
    this.setState({ [e.target.name]: !this.state[e.target.name] });

  onSelectProduct = producto => {
    const { productos } = this.state;
    const indexProducto = productos.findIndex(
      prod => prod.id_producto === producto.id
    );
    if (indexProducto >= 0) {
      notificationError(
        "El producto ya se encuentra agregado en el pedido actual"
      );
      return;
    }

    producto.id_producto = producto.id;
    producto.costo = 0;
    producto.cantidad = 0;
    producto.distribucion = [];

    if (this.canEditDistribution()) {
      this.props.local.locals.forEach(local => {
        producto.distribucion.push({
          cantidad: "0",
          local,
          id_local: local.id
        });
      });
    }
    productos.splice(0, 0, producto);
    // productos.push(producto);
    this.setState({
      productos
    });
  };

  onProductoCantidadChange = (producto, e) => {
    if (!isEnterKey(e)) return;

    const input = document.getElementById(
      `txt_producto_cantidad${producto.id_producto}`
    );
    if (input) {
      const { productos } = this.state;
      const indexProducto = productos.findIndex(
        prod => prod.id_producto === producto.id_producto
      );
      if (indexProducto >= 0) {
        const nueva_cantidad = input.value;
        productos[indexProducto].cantidad = nueva_cantidad;
        this.setState({
          productos
        });
      }
    }
  };

  onProductoDistCantidadChange = (producto, local, e) => {
    if (!isEnterKey(e)) return;
    const { id_producto } = producto;
    const { id } = local;
    const input = document.getElementById(
      `txt_dist_cantidad${id_producto}${id}`
    );
    if (input) {
      const { productos } = this.state;
      const indexProducto = productos.findIndex(
        prod => prod.id_producto === producto.id_producto
      );

      if (indexProducto >= 0) {
        const { distribucion } = productos[indexProducto];
        const indexDistribucion = distribucion.findIndex(
          dist => dist.local === local
        );

        if (indexDistribucion >= 0) {
          distribucion[indexDistribucion].cantidad = input.value;
          productos[indexProducto].distribucion = distribucion;

          this.setState({ productos });
        }
      }
    }
  };

  canEditDistribution = () => {
    const {
      user: {
        user: { admin }
      }
    } = this.props;
    return admin && this.state.agregar_distribucion;
  };

  onDeleteProductClick = producto => {
    this.setState({
      productos: this.state.productos.filter(prod => prod !== producto)
    });
  };

  getOrderDetails = () => {
    const {
      codigo,
      fecha_entrega,
      id_proveedor,
      agregar_distribucion,
      errors: { codigo_error, proveedor_error, fecha_entrega_error }
    } = this.state;
    const { providers } = this.props.provider;
    const providersActions = providersToSelectOptions(providers);
    return (
      <div className="card">
        <div className="card-content">
          <h5>Detalles del pedido</h5>

          <div className="row">
            <TextInputField
              id="codigo"
              value={codigo}
              onchange={this.onChangeTextInput}
              label="Codigo (opcional)"
              error={codigo_error}
            />
          </div>

          <div className="row">
            <SelectInputField
              id="id_proveedor"
              input_size="s12 m6"
              label="Proveedor"
              onchange={this.onChangeTextInput}
              value={id_proveedor}
              options={providersActions}
              error={proveedor_error}
            />

            <TextInputField
              input_size="s12 m6"
              id="fecha_entrega"
              label="Fecha de entrega"
              value={fecha_entrega}
              type="date"
              onchange={this.onChangeTextInput}
              error={fecha_entrega_error}
            />
          </div>

          <div className="row">
            <CheckInputField
              id="agregar_distribucion"
              checked={agregar_distribucion}
              label="Agregar distribucion"
              onchange={this.onChangeCheckField}
            />
          </div>
        </div>
      </div>
    );
  };

  getOrderProducts = () => {
    const { productos } = this.state;
    let total_productos = 0;
    return (
      <div className="card">
        <div className="card-content">
          <h5 className="mb-1">
            Productos {productos.length}
            <button
              className="btn modal-trigger right circle-element "
              data-target="search_product_and_show_info"
              style={{ height: "35px", width: "35px", padding: "0px" }}
            >
              <i className="material-icons">add</i>
            </button>
          </h5>

          {productos.map(prod => {
            total_productos += parseInt(prod.cantidad);
            return this.getProviderProductCard(prod);
          })}

          <span className="d-block mt-1">
            Total cantidad: {total_productos}
          </span>
        </div>
      </div>
    );
  };

  getProviderProductCard = producto => {
    const { nombre, codigo_barra, cantidad, id_producto } = producto;
    return (
      <div className="card" key={uuid()}>
        <div className="card-content">
          <h5>
            {nombre}
            <i
              className="material-icons right cursor-pointer"
              style={{ color: "red" }}
              onClick={this.onDeleteProductClick.bind(this, producto)}
            >
              close
            </i>
          </h5>
          <span className="d-block">Codigo: {codigo_barra}</span>

          <div className="row">
            <div className="col s12 m6">
              Cantidad:
              <div className="input-field inline">
                <input
                  id={`txt_producto_cantidad${id_producto}`}
                  type="number"
                  className="validate"
                  defaultValue={cantidad}
                  onKeyUp={this.onProductoCantidadChange.bind(this, producto)}
                />
              </div>
            </div>
          </div>
          {this.getProductoDistribucionContent(producto)}
        </div>
      </div>
    );
  };

  getProductoDistribucionContent = producto => {
    let distContent;
    if (!this.canEditDistribution()) return distContent;

    const { id_producto, distribucion } = producto;
    distContent = (
      <table>
        <thead>
          <tr>
            <th>Local</th>
            <th style={{ width: "40%" }}>Cantidad</th>
          </tr>
        </thead>

        <tbody>
          {distribucion.map(dist => (
            <tr key={uuid()}>
              <td>
                {dist.local.codigo} - {dist.local.nombre}
              </td>
              <td>
                <input
                  id={`txt_dist_cantidad${id_producto}${dist.local.id}`}
                  className="input-row-transparent"
                  defaultValue={dist.cantidad}
                  onKeyUp={this.onProductoDistCantidadChange.bind(
                    this,
                    producto,
                    dist.local
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    return distContent;
  };

  getLoadingSpinner = () => {
    const { local, provider, order } = this.props;
    if (provider.loading) {
      return <Spinner fullWidth message="Cargando proveedores..." />;
    }
    if (this.canEditDistribution() && local.loading) {
      return <Spinner fullWidth message="Cargando locales..." />;
    }
    if (order.loading) {
      return <Spinner fullWidth message="Cargando pedido..." />;
    }
    return null;
  };

  onSaveOrder = () => {
    const { codigo, fecha_entrega, id_proveedor, productos } = this.state;
    if (
      productos.filter(prod => parseInt(prod.cantidad) > 0).length === 0 ||
      isEmpty(codigo) ||
      isEmpty(fecha_entrega)
    ) {
      return;
    }
    let newOrderData = {
      codigo,
      fecha_entrega,
      id_proveedor,
      productos,
      es_compra: false
    };
    // if (!this.canEditDistribution()) {
    //   newOrderData.productos.forEach(producto => {
    //     producto.distribucion = [
    //       {
    //         id_local: this.props.user.currentLocal.id,
    //         cantidad: producto.cantidad
    //       }
    //     ];
    //   });
    // }
    this.props.createOrder(newOrderData, this.props.history);
  };

  render() {
    return (
      <React.Fragment>
        <NewNavbar active_nav="PEDIDOS">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Nuevo pedido a proveedor
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>

            <ul className="right">
              <li>
                <a href="#!" onClick={this.onSaveOrder}>
                  <i className="material-icons">save</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavbar>

        <main>
          <div className="row">
            <div className="col s12">
              {this.getLoadingSpinner()}
              {this.getOrderDetails()}
              {this.getOrderProducts()}
            </div>
          </div>
        </main>

        <SearchProductModal onSelectProduct={this.onSelectProduct} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  provider: state.provider,
  local: state.local,
  user: state.user,
  order: state.order,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getProviders, createOrder, getLocals }
)(NewOrderProvider);
