import React from "react";
import BaseComponent from "../../basecomponents/BaseComponent";

import NewNavBar from "../../layout/NewNavbar";
import uuid from "uuid";

import {
  configMaterialComponents,
  removeMaterialComponents,
} from "../../../utils/MaterialFunctions";

import { connect } from "react-redux";
import { getClients, searchClient } from "../../../actions/clientActions";
import { Link } from "react-router-dom";
import SearchClientModal from "../../layout/modals/SearchClientModal";

import Spinner from "../../common/Spinner";
import EmptyIcon from "../../common/EmptyIcon";
import PaginationBar from "../../common/PaginationBar";

class Clients extends BaseComponent {
  state = {
    field: "",
    searching: false,
  };

  componentWillMount() {
    removeMaterialComponents();
  }

  componentDidMount() {
    configMaterialComponents();
    this.setPaginationConfig({
      getResultsFunctionName: "getClients",
      propsObjectName: "clients",
      propsElementsName: "clients",
    });
    this.props.getClients();
  }

  onSearch = (e) => {
    e.preventDefault();
    this.setState({
      searching: true,
    });
    this.props.searchClient({ field: this.state.field });
  };

  onChangeTextInput = (e) => this.setState({ [e.target.name]: e.target.value });

  getAll = () => {
    this.props.getClients();
  };

  onTablePageClick = (newPage) => {
    this.getResultsForComponent({ currentPage: newPage });
  };

  getPageContent = () => {
    const { clients, loading, pagination } = this.props.clients;
    let clientsContent;

    if (loading) {
      clientsContent = <Spinner fullWidth />;
    } else if (clients.length > 0) {
      clientsContent = (
        <table className="table-stripped table-bordered highlight">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>RTN</th>
              <th>Codigo</th>
              <th>Telefono</th>
              <th>Correo</th>
              <th>Empresa</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={uuid()}>
                <td>
                  <div className="link-normal">
                    <Link to={`clientes/${client.id}`}>{client.id}</Link>
                  </div>
                </td>
                <td>
                  <div className="link-normal">
                    <Link to={`clientes/${client.id}`}>{client.nombre}</Link>
                  </div>
                </td>
                <td>{client.rtn}</td>
                <td>{client.codigo}</td>
                <td>{client.telefono}</td>
                <td>{client.correo}</td>
                <td>{client.es_empresa ? "Si" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return <EmptyIcon message="No hay clientes" />;
    }

    return (
      <>
        <div className="row mt-1">
          <div className="col s12">{clientsContent}</div>
        </div>
        <div className="row">
          <div className="col s12">
            <PaginationBar
              pagination={pagination}
              onPageClick={this.onTablePageClick}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { field } = this.state;

    return (
      <React.Fragment>
        <NewNavBar active_nav="CLIENTES">
          <div className="nav-wrapper">
            <a href="#!" className="brand-logo">
              Clientes
            </a>
            <a href="#!" className="sidenav-trigger" data-target="nav_sidenav">
              <i className="material-icons">menu</i>
            </a>

            <ul className="right">
              <li>
                <a
                  href="#modal_buscar_cliente"
                  className="tooltipped modal-trigger"
                  data-position="left"
                  data-tooltip="Buscar"
                >
                  <i className="material-icons cursor-pointer">search</i>
                </a>
              </li>
            </ul>
          </div>
        </NewNavBar>
        <main>
          <SearchClientModal
            onsearch={this.onSearch}
            onchange={this.onChangeTextInput}
            onGetAll={this.getAll}
            values={{ field }}
          />
          {this.getPageContent()}
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.client,
});

export default connect(mapStateToProps, { getClients, searchClient })(Clients);
